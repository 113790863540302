import { BASE_URL } from "../../../config";
import ApiService from "../../../services/ApiService";

export class AdminService {
  constructor() {
    this.apiService = ApiService.getInstance();
    this.baseApiRoute = '/admin';
  }

  grantRole(payload) {
    return this.apiService.put(`${BASE_URL}${this.baseApiRoute}/users/grantRole`, payload);
  }
}
