import React from 'react';
import {
    Stack,
    Container,
    Grid
} from '@mui/material';
import Page from '../../components/Page/Page';
import styles from './components/PrivacyPolicy.module.scss';

export default function PrivacyPolicyView() {
    return (
        <Page>
            <Container>
            <Grid className={styles.grid} container spacing={3}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <div>
                        <h1>Privacy Policy for KAAATSCHING‼!</h1>
                        <section>
                        <h2>1. Introduction</h2>
                        <p>
                        Welcome to KAAATSCHING‼! the platform for fair and transparent revenue share using blockchain technology. We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect your personal data when you use our streaming platform and related services. By accessing or using our services, you agree to the terms of this Privacy Policy.
                        </p>
                        </section>
                        <section>
                        <h2>2. Information We Collect</h2>
                        <p>
                        We collect and process various types of information to provide you with our services and to comply with legal obligations. The information we collect includes, but is not limited to:
                        <ul className={styles.ul}>
                            <li><b>Personal Information: </b>We may collect personal information such as your name, email address and account details when you sign up for our platform.</li> 
                            <li><b>Usage Information:  </b>We collect information about how you interact with our platform and your interaction with features.</li> 
                            <li><b>Device Information: </b>We may collect information about the devices you use to access our platform, such as device type, operating system, and unique device identifiers.</li> 
                            <li><b>Location Information: </b>With your consent, we may collect and process location data to provide you with localized content and services.</li> 
                        </ul>
                        </p>

                        </section>

                        <section>
                        <h2>3. How We Use Your Information</h2>
                        <p>
                        We use the collected information for various purposes, including:
                        <ul className={styles.ul}>
                            <li>Providing and improving our services.</li> 
                            <li>Personalizing your experience.</li> 
                            <li>Processing payments to your account and managing information about your projects.</li> 
                            <li>Communicating with you about updates, promotions, and account-related matters.</li> 
                            <li>Analyzing usage patterns to enhance user experience.</li>
                            <li>Complying with legal obligations and regulations.</li>
                        </ul>
                        </p>

                        </section>

                        <section>
                        <h2>4. Data Sharing and Disclosure</h2>
                        <p>
                        We may share your information under the following circumstances:
                        <ul className={styles.ul}>
                            <li><b>Service Providers: </b>We may share data with third-party service providers to assist in delivering our services, including payment processing, and customer support.</li> 
                            <li><b>Legal Compliance: </b>We may share information to comply with legal obligations, respond to legal requests, or protect our rights, privacy, safety, or property.</li> 
                            <li><b>Affiliates and Business Transfers: </b>In the event of a merger, acquisition, or sale of assets, your information may be transferred to the acquiring entity.</li> 
                            <li><b>With Your Consent: </b>We will share your information when you give us explicit consent to do so.</li> 
                        </ul>
                        </p>

                        </section>

                        <section>
                        <h2>5. Your Rights</h2>
                        <p>
                        In accordance with applicable laws and regulations, you have certain rights regarding your personal information, including:
                        <ul className={styles.ul}>
                            <li>Access: You can request a copy of the personal data we hold about you.</li> 
                            <li>Correction: You can correct inaccuracies in your personal data.</li> 
                            <li>Deletion: You can request the deletion of your personal data under certain circumstances.</li> 
                            <li>Objection: You can object to the processing of your personal data for specific purposes.</li> 
                        </ul>
                        </p>

                        </section>

                        <section>
                        <h2>6. Data Security</h2>
                        <p>
                        We implement technical and organizational measures to safeguard your personal information from unauthorized access, disclosure, or destruction. However, no method of transmission over the internet is completely secure, and we cannot guarantee absolute security.
                        </p>
                        </section>

                        <section>
                        <h2>7. International Data Transfers</h2>
                        <p>
                        As a global platform, your data may be transferred to and processed in countries outside of your own. We will ensure that such transfers comply with applicable data protection laws.
                        </p>
                        </section>

                        <section>
                        <h2>8. Changes to Privacy Policy</h2>
                        <p>
                        We may update this Privacy Policy from time to time. We will notify you of any material changes through our platform or other communication methods.
                        </p>
                        </section>

                        <section>
                        <h2>9. Contact Us</h2>
                        <p>
                        If you have any questions, concerns, or requests regarding your privacy or this Privacy Policy, please contact our Data Protection Officer at <a href="mailto:donald.schubert@dgc.org">donald.schubert@dgc.org</a>.<br /><br />

                        Thank you for choosing KAAATSCHING‼! We are committed to providing you with a secure and enjoyable user experience. <br /> <br />
                        </p>
                        </section>

                        <section>
                            <address>
                            Don Schubert, <br />
                            Managing CEO <br />
                            KAAATSCHING‼! <br />
                            Stolzestr. 10, D-50674 COLOGNE <br />
                            26.08.2023 <br />

                            </address>
                        </section>
                        
                        </div>
                        
                    </Stack>
                </Grid>
            </Container>
        </Page>
    );
}
