import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { Button, FormControlLabel, Switch } from '@mui/material';
import Container from '@mui/material/Container';
import styles from "../../Registration/components/Register.module.scss";
import { ContentStyle } from '../../Project/components/CreateProjectForm.styles';
import { translate } from '../../../utils/localization/translate';
import { useAppDeps } from '../../../App';
import { USER_SETTINGS } from '../../../services/StorageService';
import useToast from '../../../utils/hooks/useToast';

export const SettingsForm = () => {
    const { t } = translate();
    const toast = useToast();
    const { storageService } = useAppDeps();
    const userSettings = JSON.parse(storageService.getItemFromLocalStorage(USER_SETTINGS));
    const [loginSound, setLoginSound] = useState(userSettings ? userSettings.audio : true);

    const initialValues = {
        audio: userSettings ? userSettings.audio : true,
    };

    const [isDataChanged, setIsDataChanged] = useState(loginSound !== initialValues.audio);

    useEffect(() => {
        if (loginSound !== initialValues.audio) {
            setIsDataChanged(true);
        } else {
            setIsDataChanged(false);
        }
    }, [loginSound]);

    const onSubmit = async () => {
        const payload = {
            "audio": loginSound,
        }

        await storageService.setItemToLocalStorage(USER_SETTINGS, JSON.stringify(payload));

        toast.show({
            message: t('Data saved.'),
            severity: 'success',
            wait: 3000,
        });
        setIsDataChanged(false);
    };

    const updateLoginSound = (value) => {
        setLoginSound(value);
    }

    return (
        <ContentStyle className={styles.contentStyle}>
            <Container component="main">
                <Formik onSubmit={onSubmit} initialValues={initialValues}>
                    {(props) => (
                        <Form className={styles.formContainer}>
                            <div className={styles.flex_col} style={{ alignItems: 'flex-start' }}>
                                <FormControlLabel style={{ marginTop: 10, marginBottom: 10, padding: 2, whiteSpace: 'nowrap', flexDirection: 'row-reverse' }}
                                    control={<Switch checked={loginSound} onChange={(e) => {
                                        updateLoginSound(e.target.checked)
                                    }}
                                        inputProps={{ 'aria-label': 'controlled' }} />}
                                    label={t('Play sound on Sign In')}
                                    labelPlacement="end"
                                    name="audio"
                                />
                            </div>
                            <Button type="submit" variant="contained" className={`${styles.signUpButtonContainer} ${styles.saveButton}`} fullWidth disabled={!isDataChanged}>
                                {props.isSubmitting ? t('Loading') : t('Save')}
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Container>
        </ContentStyle>
    );
}