import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { ForgotPasswordVIew } from '../pages/ForgotPassword/ForgotPasswordView';
import { LoginVIew } from '../pages/Login/LoginView';
import { NewPasswordVIew } from '../pages/NewPassword/NewPasswordView';
import { RegistrationView } from '../pages/Registration/RegistrationView';
import VerificationView from '../pages/Verification/VerificationView';
import VerifyEmailView from '../pages/Verification/VerifyEmailView';
import {
  EMAIL_VERIFICATION_PAGE,
  FORGOT_PASSWORD_PAGE,
  LOGIN_PAGE,
  NEW_PASSWORD_PAGE,
  REGISTRATION_PAGE,
  VERIFICATION_PAGE,
  TERMS_OF_SERVICE,
  PRIVACY_POLICY,
  ROLE_CHANGE_PAGE
} from './constants/RouteNames';
import DashboardRouter from './dashboard/DashboardRouter';
import PrivacyPolicyView from '../pages/PrivacyPolicyAndTermsOfService/PrivacyPolicyView';
import TermsOfServiceView from '../pages/PrivacyPolicyAndTermsOfService/TermsOfServiceView';
import ChangeUserRoleView from '../pages/AssignRole/ChangeUserRoleView';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginVIew />} />
        <Route path={LOGIN_PAGE} element={<LoginVIew />} />
        <Route path={REGISTRATION_PAGE} element={<RegistrationView />} />
        <Route path={FORGOT_PASSWORD_PAGE} element={<ForgotPasswordVIew />} />
        <Route path={NEW_PASSWORD_PAGE} element={<NewPasswordVIew />} />
        <Route path={VERIFICATION_PAGE} element={<VerificationView />} />
        <Route path={EMAIL_VERIFICATION_PAGE} element={<VerifyEmailView />} />
        <Route path={TERMS_OF_SERVICE} element={<TermsOfServiceView />} />
        <Route path={PRIVACY_POLICY} element={<PrivacyPolicyView />} />
        <Route path={ROLE_CHANGE_PAGE} element={<ChangeUserRoleView />} />
        <Route path="/*" element={<DashboardRouter />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
