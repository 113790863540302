import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import { Input, Slide, Button, IconButton, InputAdornment, ClickAwayListener } from '@mui/material';
import Iconify from '../Iconify/Iconify';
import useProjectService from '../../pages/Project/consumers/useProjectService';
import { PROJECT_DETAILS_PAGE } from '../../router/constants/RouteNames';
import styles from './SearchResults.module.scss';
import { translate } from '../../utils/localization/translate';

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled('div')(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  backgroundColor: `${alpha(theme.palette.background.default, 0.72)}`,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const SearchResults = styled('div')(({ theme }) => ({
  zIndex: '2000',
  color: 'black',
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  top: APPBAR_MOBILE,
  right: '0',
  left: '0',
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  backgroundColor: `${alpha(theme.palette.background.default, 1)}`,
  [theme.breakpoints.up('md')]: {
    top: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

export default function Searchbar() {
  const [isOpen, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [foundProjects, setFoundProjects] = useState(null);
  const { searchProjects } = useProjectService();
  const navigate = useNavigate();
  const { t } = translate();

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setFoundProjects(null);
    setOpen(false);
  };

  const handleOnInputChange = async (e) => {
    const searchValue = e.target.value;

    setSearchQuery(searchValue);
    onSearch();
  }

  const onSearch = () => {
    const projects = searchProjects(searchQuery);

    setFoundProjects(projects);
  }

  const handleOnResultClick = (id) => {
    setOpen(false);
    setFoundProjects(null);

    navigate(PROJECT_DETAILS_PAGE.replace(':id', id));
  }

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!isOpen && (
          <IconButton onClick={handleOpen}>
            <Iconify icon="eva:search-fill" width={20} height={20} />
          </IconButton>
        )}

        <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
          <SearchbarStyle>
            <Input
              autoFocus
              fullWidth
              disableUnderline
              placeholder={`${t('Search projects')}…`}
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
              onChange={(e) => handleOnInputChange(e)}
            />
            <Button variant="contained" className={styles.buttonPrimary} onClick={handleClose}>
              {t('Cancel')}
            </Button>
          </SearchbarStyle>
        </Slide>

        {isOpen && foundProjects &&
          <SearchResults>
            {foundProjects.map(item => (
              <Button type='button' onClick={() => handleOnResultClick(item.projectId)} key={item.projectId} className={styles.link}>
                {item.projectName}
              </Button>
            ))}
          </SearchResults>
        }
      </div>
    </ClickAwayListener>
  );
}
