import { HelmetProvider } from 'react-helmet-async';
import './App.scss';
import ThemeProvider from "./theme";
import Router from "./router/Router";
import AuthService from './services/AuthService';
import StorageService from './services/StorageService';
import dependencyInjection from './utils/dependencyInjection';
import { AppContextProvider } from './context/App/App.context';
import { FileUploadService } from './services/FileUploadService';
import { MobileAppNotification } from './components/MobileAppNotification/MobileAppNotification';

const authService = AuthService.getInstance();
const storageService = StorageService.getInstance();
const fileUploadService = new FileUploadService();

const [AppDependencyProvider, useAppDeps] = dependencyInjection({
  services: {
    authService,
    storageService,
    fileUploadService,
  },
});

function App() {
  return (
    <HelmetProvider>
      <ThemeProvider>
        <AppDependencyProvider>
          <AppContextProvider>
            <Router />
            <MobileAppNotification />
          </AppContextProvider>
        </AppDependencyProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;

export { useAppDeps };
