import MuiAlert from '@mui/material/Alert';
import styled from 'styled-components';

export const Alert = styled(MuiAlert)`
  .MuiAlert-message {
    display: flex;
    align-items; center;
    padding: 8px 0;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
  }

  .MuiAlert-icon {
    align-items: center;
    justify-content: center;
    padding: 0;
  }
`;
