import { BASE_URL } from "../../../config";
import ApiService from "../../../services/ApiService";

export class ProjectService {
  constructor() {
    this.apiService = ApiService.getInstance();
    this.baseApiRoute = '/projects';
  }

  async createProject(payload) {
    const response = await this.apiService.postNoConfig(`${BASE_URL}/owner${this.baseApiRoute}/create`, payload);

    return response;
  }

  async getUserProjects() {
    const response = await this.apiService.get(`${BASE_URL}${this.baseApiRoute}/getAllProjects`);

    return response.data;
  }

  async getNotaryProjects() {
    const response = await this.apiService.get(`${BASE_URL}/notary${this.baseApiRoute}/getAllProjectsForNotary`);

    return response.data;
  }

  async getOwnerProjects() {
    const response = await this.apiService.get(`${BASE_URL}/owner${this.baseApiRoute}/getAllProjectsForOwner`);

    return response.data;
  }

  async setPictureLock(payload) {
    const response = await this.apiService.put(`${BASE_URL}/owner/projects/setPictureLock`, payload);

    return response.data;
  }

  async addRevenue(payload) {
    const response = await this.apiService.put(`${BASE_URL}/notary/projects/addRevenue`, payload);

    return response.data;
  }

  async payoutUser(payload) {
    const response = await this.apiService.put(`${BASE_URL}/notary/projects/pay/user`, payload);

    return response.data;
  }

  async getUsersForProjectNotary(id) {
    const response = await this.apiService.get(`${BASE_URL}/notary/projects/getAllProjectUsersForNotary?projectId=${id}`);

    return response.data;
  }

  async getUsersForProjectOwner(id) {
    const response = await this.apiService.get(`${BASE_URL}/owner/projects/getAllProjectUsersForOwner?projectId=${id}`);

    return response.data;
  }

  async updateQuota(payload) {
    const response = await this.apiService.put(`${BASE_URL}/owner/projects/updateQuota`, payload);

    return response.data;
  }

  async addUsers(payload) {
    const response = await this.apiService.put(`${BASE_URL}/owner/projects/addMembers`, payload);

    return response.data;
  }

  async getHistoricChartData(payload) {
    const response = await this.apiService.post(`${BASE_URL}${this.baseApiRoute}/projectHistory`, { "projectsArray": payload});

    return response.data;
  }
}
