import makeContextStore from '../../utils/makeContextStore';
import AppReducer from './App.reducer';

const initialState = {
  projectData: null,
  language: localStorage.getItem('language') || 'en',
  breakdownChartData: [
    {
      id: '0',
      projectName: 'No projects',
      revenues: [],
    }
  ],
};

const [AppContextProvider, useAppContextStore, useAppDispatch] = makeContextStore(AppReducer, initialState);

export { AppContextProvider, useAppContextStore, useAppDispatch };
