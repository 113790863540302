import Snackbar from '@mui/material/Snackbar';

import * as Styled from './Toast.module';

export function Toast(props) {
    return (
        <Snackbar
            key={props.key}
            open={props?.open ?? false}
            autoHideDuration={props.autoHideDuration}
            anchorOrigin={props.anchorOrigin}
            onClose={props.onClose}
            style={{zIndex: 2010}}
        >
            <Styled.Alert severity={props.severity} onClose={props.onClose}>
                {props.message}
            </Styled.Alert>
        </Snackbar>
    );
}
