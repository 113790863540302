import { useEffect, useState } from 'react';
import { CircularProgress, Button } from '@mui/material';
import styles from './LoadingButton.module.scss';

export const LoadingButton = ({ handleOnClick = () => { }, label = '', isError }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (isError) {
            setIsSubmitting(false);
        }
    }, [isError]);

    const onButtonClick = async () => {
        setIsSubmitting(true);
        await handleOnClick();

        setIsSubmitting(false);
    }

    return (
        <Button className={styles.buttonPrimary} variant="contained" onClick={() => onButtonClick()} disabled={isSubmitting}>
            {isSubmitting ? <CircularProgress className={styles.circularProgress} /> : label}
        </Button>
    );
}