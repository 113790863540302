import { translate } from '../../utils/localization/translate';
import styles from './MobileAppNotification.module.scss';

export const MobileAppNotification = () => {
    const { t } = translate();

    const handleOnClose = () => {
        document.getElementById("mobileAppNotificationWrapper").style.display = 'none';
    }

    return (
        <div id='mobileAppNotificationWrapper' className={styles.mobileAppNotificationWrapper}>
            <div className={styles.mobileAppNotification}>
                <span>{t('For a better experience use our mobile app.')}</span>
                <a href="#">iOS</a>
                <a href="#">Android</a>

                <button type='button' className={styles.closeButton} onClick={handleOnClose}>X</button>
            </div>
        </div>
    );
}