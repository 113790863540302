import React, { useEffect, useState } from 'react';
import { Grid, Container, Typography, CircularProgress } from '@mui/material';
import Page from '../../components/Page/Page';
import { USER_KEY } from '../../services/StorageService';
import { ProjectRevenueComponent } from './components/ProjectRevenueComponent';
import { ProjectBreakdownComponent } from './components/ProjectBreakdownComponent';
import { ProjectListComponent } from './components/ProjectListComponent';
import { useAppDeps } from '../../App';
import useProjectService from '../Project/consumers/useProjectService';
import styles from './components/ProjectListComponent.module.scss';
import { ProjectsTable } from '../Project/components/ProjectsTable';
import { translate } from '../../utils/localization/translate';


export default function HomeView() {
  const { storageService } = useAppDeps();
  const { getUserProjects, getNotaryProjects, getOwnerProjects } = useProjectService();
  const jsonUser = storageService.getItem(USER_KEY);
  const { t } = translate();

  const user = JSON.parse(jsonUser);

  const [topUserProjects, setTopUserProjects] = useState([]);
  const [allUserProjects, setAllProjects] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getProjects();
  }, []);

  const getProjects = async () => {
    if (user.role === 'notary') {
      const projects = await getNotaryProjects();
      setAllProjects(projects);

      setIsLoading(false);
      return;
    }

    if (user.role === 'owner') {
      const projects = await getOwnerProjects();
      setAllProjects(projects);

      const topProjects = getTopProjects(projects);
      setTopUserProjects(topProjects);

      setIsLoading(false);
      return;
    }

    const projects = await getUserProjects();
    setAllProjects(projects);

    const topProjects = getTopProjects(projects);
    setTopUserProjects(topProjects);

    setIsLoading(false);
  };

  const getTopProjects = (projects) => {
    const finishedProjects = projects.filter(item => item.projectDetails?.projectStage === 1 && item.projectDetails?.userTO);

    return finishedProjects.slice(-4);
  }

  const getPieChartProjects = () => {
    return allUserProjects.filter(item => item.projectDetails?.projectStage === 1 && item.projectDetails?.userTO);
  }

  const getContent = () => {
    switch (user.role) {
      case 'notary':
        return (
          <Container>
            <ProjectsTable projects={allUserProjects} isNotary={true} />
          </Container>
        );
      default:
        return (
          <>
            {topUserProjects.length > 0 &&
              <>
                <Typography variant='h5' style={{ paddingLeft: 24 }}>{t('Latest projects')}</Typography>
                <div className={styles.listGrid}>
                  <ProjectListComponent userProjects={topUserProjects} />
                </div>
              </>
            }
            <div className={styles.graphGrid}>
              <ProjectRevenueComponent userProjects={getPieChartProjects()} />
            </div>
          {
            <div className={styles.graphGrid}>
               <ProjectBreakdownComponent />
            </div>
          }
          </>
        );
    }
  }

  return (
    <Page>
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          {t('Hi')},
          {' '}
          {`${user.accountName}`}
        </Typography>
        <Grid container spacing={3} marginTop='24px'>
          {isLoading ? <CircularProgress /> : getContent()}
        </Grid>
      </Container>
    </Page>
  );
}
