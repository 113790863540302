import { Grid, Paper, Box } from '@mui/material';
import styles from '../Login/components/Login.module.scss';
import Logo from '../../components/Logo/Logo';
import { NewPasswordForm } from './components/NewPasswordForm';
import { translate } from '../../utils/localization/translate';

export const NewPasswordVIew = () => {
  const { t } = translate();

  return (
    <Grid className={styles.mainContainer}>
      <Box className={styles.box}>
        <Paper className={styles.paper}>
          <Grid className={styles.grid}>
            <Logo disabledLink={true} />
          </Grid>
          <div>
            <p className={styles.heading}>{t('New password')}</p>
          </div>
          <NewPasswordForm />
        </Paper>
      </Box>
    </Grid>
  );
};
