import { AppActionType } from './App.types';

const AppReducer = (state, action) => {
  switch (action.type) {
    case AppActionType.SET_PROJECT_DATA:
      return { ...state, projectData: action.payload };
    case AppActionType.SET_LANGUAGE:
      return { ...state, language: action.payload };
    case AppActionType.SET_BREAKDOWN_CHART:
      return { ...state, breakdownChartData: action.payload };

    default:
      return state;
  }
};

export default AppReducer;
