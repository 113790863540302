import { Button, Card, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import { useState } from 'react';
import { filter } from 'lodash';
import Scrollbar from '../Scrollbar/Scrollbar';
import SearchNotFound from '../SearchNotFound/SearchNotFound';
import TableHeadComponent from '../TableHead/TableHead';
import styles from './SearchResults.module.scss';
import { useAppDeps } from '../../App';
import { translate } from '../../utils/localization/translate';

export const SearchResults = ({ results }) => {
    const { storageService } = useAppDeps();
    const [clipBoardTooltip, setClipBoardTooltip] = useState('Copy to Clipboard');
    const { t } = translate();

    const TABLE_HEAD = [
        { id: 'username', label: t('Username'), alignRight: false },
        { id: 'address', label: t('Address'), alignRight: false },
        { id: 'email', label: t('Email'), alignRight: false },
    ];

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('name');

    // we leave the unused setFilterName here because it might be needed in the future when search can return multiple values.
    // eslint-disable-next-line no-unused-vars
    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function applySortFilter(array, comparator, query) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) { return order; }
            return a[1] - b[1];
        });
        if (query) {
            return filter(array, (_user) => _user.accountName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
        }
        return stabilizedThis.map((el) => el[0]);
    }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = results.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleCopyToClipboard = (value) => {
        storageService.copyToClipboard(value);
        setClipBoardTooltip('Copied');
    }

    const getClipBoardIcon = () => {
        if (clipBoardTooltip === 'Copied') {
            return <CheckIcon />;
        }

        return <ContentCopyIcon />;
    }

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - results.length) : 0;

    const filteredResults = applySortFilter(results, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredResults.length === 0;

    return (
        <>
            <Card className={`${styles.searchResults}`}>
                <Scrollbar sx={{ height: { xs: 140, sm: 'auto' } }}>
                    <TableContainer sx={{ minWidth: 800 }}>
                        <Table>
                            <TableHeadComponent
                                headLabel={TABLE_HEAD}
                                withCheckbox={false}
                                order={order}
                                orderBy={orderBy}
                                rowCount={results.length}
                                numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                                onSelectAllClick={handleSelectAllClick}
                            />
                            <TableBody>
                                {filteredResults.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                    const { parcId, accountName, email } = row;

                                    const isItemSelected = selected.indexOf(accountName) !== -1;

                                    return (
                                        <TableRow
                                            hover
                                            key={parcId}
                                            tabIndex={-1}
                                            role="checkbox"
                                            selected={isItemSelected}
                                            aria-checked={isItemSelected}
                                        >
                                            <TableCell align='left' component="th" scope="row" padding="normal">{accountName}</TableCell>
                                            <TableCell align="left">
                                                {parcId}
                                                <Tooltip title={t(clipBoardTooltip)}>
                                                    <Button className={styles.copyToClipboardButton} onClick={() => handleCopyToClipboard(parcId)}>
                                                        {getClipBoardIcon()}
                                                    </Button>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell component="th" scope="row" padding="none">{email}</TableCell>
                                        </TableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>

                            {isUserNotFound && (
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                            <SearchNotFound searchQuery={filterName} />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Scrollbar>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={results.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t('Rows per page:')}
                />
            </Card>
        </>
    );
}