let instance = null;

class StorageService {
    getInstance() {
        if (!instance) {
            instance = new StorageService();
        }
        return instance;
    }

    setItem(key, value) {
        sessionStorage.setItem(key, value);
    }

    getItem(key) {
        return sessionStorage.getItem(key);
    }

    clearItem(key) {
        sessionStorage.removeItem(key);
    }

    copyToClipboard(value) {
        navigator.clipboard.writeText(value)
    }

    setItemToLocalStorage(key, value) {
        localStorage.setItem(key, value);
    }

    getItemFromLocalStorage(key){
        return localStorage.getItem(key);
    }
}

export default new StorageService();

export const JWT_TOKEN_KEY = 'jwtToken';

export const REFRESH_TOKEN_KEY = 'refreshToken';

export const USER_KEY = 'user';

export const USER_SETTINGS = 'settings';