import { Box, Grid, Paper } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import dependencyInjection from '../../utils/dependencyInjection';
import { VerificationForm } from './components/VerificationForm';
import { VerificationService } from './services/Verification.Service';
import styles from "./components/VerificationForm.module.scss";
import Logo from '../../components/Logo/Logo';

// ----------------------------------------------------------------------
const verificationService = new VerificationService();

const [VerifyUserDependencyProvider, useVerifyUserDeps] = dependencyInjection({
  services: {
    verificationService,
  },
});

export default function VerificationView() {
  const params = useParams();
  return (
    <VerifyUserDependencyProvider>
        <Grid className={styles.grid}>
            <Box className={styles.box}>
                <Paper className={styles.paper}>
                    <Grid className={styles.logoGrid}>
                        <Logo disabledLink={true} />
                    </Grid>
                    <VerificationForm confirmationCode={params.confirmationCode}/>
                </Paper>
            </Box>
        </Grid>
        
    </VerifyUserDependencyProvider>
  );
}

export { useVerifyUserDeps };