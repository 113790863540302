import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { IconButton, Tooltip } from '@mui/material';
import { translate } from '../../utils/localization/translate';

const ProjectUserTable = ({ projectUser, removeProjectUser }) => {
  const { t } = translate();

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t('CSF name')}</TableCell>
            <TableCell align="right">{t('bps')}</TableCell>
            <TableCell align="right">{t('Amount')} (€)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {projectUser.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {`${row.address.substring(0, 4)}...${row.address.substring(row.address.length - 4, row.address.length)}`}
              </TableCell>
              <TableCell align="right">{row.basis}</TableCell>
              <TableCell align="right">{parseFloat(row.amount / 100).toFixed(2).concat(" € ")}</TableCell>
              <TableCell align="right">
                <Tooltip title="Delete User">
                  <IconButton onClick={() => removeProjectUser(index)}>
                    <DeleteForeverIcon style={{ color: "red" }} />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default ProjectUserTable