import React from 'react';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './FileItem.module.scss';

const FileItem = ({file, deleteFile}) => {
    return (
        <div id="poster-div" className={styles.listItem} key={file.name}>
            <UploadFileIcon className={styles.icons} />
            <p>{file.name}</p>
            <div className={styles.actions}>
                {file.isUploading && 
                    <CircularProgress className={styles.icons} />
                }
                {!file.isUploading && 
                    <DeleteIcon className={styles.icons} onClick={()=> deleteFile(file.name)} />
                } 
            </div>
        </div>
    )
}

export default FileItem;