import React from 'react';
import FileItem from './FileItem';

const FileList = ({file, setFile}) => {

    const deleteFileHander = async() => {
        setFile('');
    }

    return(
        <ul className='file-list'>
            {file && <FileItem file={file} deleteFile={deleteFileHander} />}
        </ul>
    )
}

export default FileList;