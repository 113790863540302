import { useAppDeps } from "../../App";
import useToast from "./useToast";
import placeholderImage from '../../assets/images/Movie-Poster-Placeholder.jpg';

export default function useFileUpload() {
    const { fileUploadService } = useAppDeps();
    const toast = useToast();

    const uploadPoster = async (payload) => {
        try {
            const response = await fileUploadService.uploadPoster(payload);

            return response.data;
        } catch (e) {
            toast.show({
                message: e.response.data.message,
                severity: 'error',
                wait: 3000,
            });

            throw e;
        }
    }

    const getPoster = async (address) => {
        try {
            const response = await fileUploadService.getPoster(address);

            return response.data;
        } catch(e) {
            return placeholderImage;
        }
    }

    return {
        uploadPoster,
        getPoster,
    };
}