import { setProjectDataAction } from "../../../context/App/App.actions";
import { useAppContextStore, useAppDispatch } from "../../../context/App/App.context";
import useToast from "../../../utils/hooks/useToast";
import { translate } from "../../../utils/localization/translate";
import { ProjectService } from "../services/Project.service";
import { USER_KEY } from '../../../services/StorageService';
import { useAppDeps } from "../../../App";

export default function useProjectService() {
    const dispatch = useAppDispatch();
    const { projectData } = useAppContextStore();
    const projectService = new ProjectService();
    const toast = useToast();
    const { t } = translate();
    const { storageService } = useAppDeps();
    const user = JSON.parse(storageService.getItem(USER_KEY));

    const createProject = async (payload) => {
        try {
            const response = await projectService.createProject(payload);

            toast.show({
                message: t('Project created.'),
                severity: 'success',
                wait: 3000,
            });

            // Reset saved projects on new project create
            dispatch(setProjectDataAction(null));
            return response.data;
        } catch (e) {
            toast.show({
                message: e.response.data.message,
                severity: 'error',
                wait: 3000,
            });

            throw e;
        }
    }

    const getUserProjects = async () => {
        // If we already have data stored we do not fetch new values.
        if (projectData) {
            console.log("yes", projectData);
            return projectData;
        }

        try {
            const projects = await projectService.getUserProjects();

            // Storing the data.
            dispatch(setProjectDataAction(projects));

            return projects;
        } catch (e) {
            toast.show({
                message: e.response.data.message,
                severity: 'error',
                wait: 3000,
            });

            throw e;
        }
    }

    const getNotaryProjects = async () => {
        // If we already have data stored we do not fetch new values.
        if (projectData) {
            return projectData;
        }

        try {
            const projects = await projectService.getNotaryProjects();

            // Storing the data.
            dispatch(setProjectDataAction(projects));

            return projects;
        } catch (e) {
            toast.show({
                message: e.response.data.message,
                severity: 'error',
                wait: 3000,
            });

            throw e;
        }
    }

    const getOwnerProjects = async () => {
        // If we already have data stored we do not fetch new values.
        if (projectData) {
            return projectData;
        }

        try {
            const projects = await projectService.getOwnerProjects();
            const userProjects = await getUserProjects();
            const listOfUserProjectIds = userProjects.map(item => item.projectId);

            const mergedArray = userProjects;
            projects.forEach(item => {
                if (!listOfUserProjectIds.includes(item.projectId)) {
                    mergedArray.push(item);
                }
            });

            // Storing the data.
            dispatch(setProjectDataAction(mergedArray));

            return mergedArray;
        } catch (e) {
            toast.show({
                message: e.response.data.message,
                severity: 'error',
                wait: 3000,
            });

            throw e;
        }
    }

    const getProjectById = async (id) => {
        let allProjects;
        if(user.role === 'owner')
        {
            allProjects = await getOwnerProjects();
        }
        else
        {
            allProjects = await getUserProjects();
        }
       
        return allProjects.find(item => item.projectId === id);
    }

    const getNotaryProjectById = async (id) => {
        const allProjects = await getNotaryProjects();

        return allProjects.find(item => item.projectId === id);
    }

    const addRevenue = async (payload) => {
        try {
            const response = await projectService.addRevenue(payload);

            toast.show({
                message: t('Revenue added.'),
                severity: 'success',
                wait: 3000,
            });

            return response.data;
        } catch (e) {
            toast.show({
                message: e.response.data.message,
                severity: 'error',
                wait: 3000,
            });

            throw e;
        }
    }

    const setPictureLock = async (payload) => {
        try {
            const response = await projectService.setPictureLock(payload);

            toast.show({
                message: t('Picture Lock set.'),
                severity: 'success',
                wait: 3000,
            });

            // Reset saved projects on set picture lock
            dispatch(setProjectDataAction(null));
            return response.data;
        } catch (e) {
            toast.show({
                message: e.response.data.message,
                severity: 'error',
                wait: 3000,
            });

            throw e;
        }
    }

    const payoutUser = async (payload) => {
        try {
            const response = await projectService.payoutUser(payload);

            toast.show({
                message: t('Success'),
                severity: 'success',
                wait: 3000,
            });

            return response.data;
        } catch (e) {
            toast.show({
                message: e.response.data.message,
                severity: 'error',
                wait: 3000,
            });

            throw e;
        }
    }

    const getUsersForProjectNotary = async (payload) => {
        try {
            const response = await projectService.getUsersForProjectNotary(payload);
            
            return response;
        } catch (e) {
            toast.show({
                message: e.response.data.message,
                severity: 'error',
                wait: 3000,
            });

            throw e;
        }
    }

    const getUsersForProjectOwner = async (payload) => {
        try {
            const response = await projectService.getUsersForProjectOwner(payload);
            
            return response;
        } catch (e) {
            if (e.response.data.message === "No Permission to view this information") {
                return null;
            }

            toast.show({
                message: e.response.data.message,
                severity: 'error',
                wait: 3000,
            });

            throw e;
        }
    }

    const updateQuota = async (payload) => {
        try {
            const response = await projectService.updateQuota(payload);

            toast.show({
                message: t('Quota updated.'),
                severity: 'success',
                wait: 3000,
            })
            
            return response.data;
        } catch (e) {
            toast.show({
                message: e.response.data.message,
                severity: 'error',
                wait: 3000,
            });

            throw e;
        }
    }

    const addUsers = async (payload) => {
        try {
            const response = await projectService.addUsers(payload);

            toast.show({
                message: t('Success'),
                severity: 'success',
                wait: 3000,
            });
            
            return response.data;
        } catch (e) {
            toast.show({
                message: e.response.data.message,
                severity: 'error',
                wait: 3000,
            });

            throw e;
        }
    }

    const searchProjects = (searchQuery) => {
        if (searchQuery === "") {
            return [];
        }

        return projectData.filter(item => item.projectName.toLowerCase().includes(searchQuery.toLowerCase()));
    }

    const getHistoricChartData = async () => {
        try {
            const projectAddresses = projectData.map(project =>  project.projectaddress);
            const response = await projectService.getHistoricChartData(projectAddresses);
            return response;
        } catch (e) {
            toast.show({
                message: e.response.data.message,
                severity: 'error',
                wait: 3000,
            });

            throw e;
        }
    }

    return {
        getUserProjects,
        createProject,
        getProjectById,
        addRevenue,
        setPictureLock,
        payoutUser,
        getUsersForProjectNotary,
        getNotaryProjects,
        getNotaryProjectById,
        getOwnerProjects,
        getUsersForProjectOwner,
        updateQuota,
        addUsers,
        searchProjects,
        getHistoricChartData,
    };
}