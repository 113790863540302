import { Button, Grid, Typography, FormControlLabel, Radio, RadioGroup, FormControl } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form} from 'formik';
import { HOME_PAGE } from '../../../router/constants/RouteNames';
import useAdminService from '../consumers/useAdminService';
import styles from './assignRoleForm.module.scss';
import { translate } from '../../../utils/localization/translate';

export const AssignRoleForm = ({ parcId }) => {
    
  const [roleType, setRoleType] = useState("owner");
  const { t } = translate();
  const { changeUserRole } = useAdminService();
  const navigate = useNavigate();

  const handleOnSubmit = async (values, props) => {
    const jsonBody = {
      parcId: `${parcId}`,
      role: roleType
    };

    await changeUserRole(jsonBody);


    props.resetForm();
    props.setSubmitting(false);
    navigate(HOME_PAGE);
  };

    const handleChange = (event) => {
        setRoleType(event.target.value);
    }

  return (
    <div className={styles.root}>
      <Formik  onSubmit={handleOnSubmit}>
        {(props) => (
          <Form>
            <Grid className={styles.formContainer} container spacing="1" alignItems="center">
              <Typography
                variant="contained"
                align="left"
                sx={{ color: 'text.primary', mt: 2, mb: -2, fontWeight: 'bold' }}
              >
                {t('Assign Role')}
              </Typography>

              <div className={styles.selectGroup}>
                <FormControl sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <RadioGroup
                    aria-labelledby="role"
                    name="roleType"
                    value={roleType}
                    defaultValue="0"
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value={"owner"}
                      control={<Radio sx={{ '&, &.Mui-checked': { color: '#cb1517' } }} />}
                      defaultChecked
                      label="owner"
                    />
                    <FormControlLabel
                      value={"notary"}
                      control={<Radio sx={{ '&, &.Mui-checked': { color: '#cb1517' } }} />}
                      disabled
                      label="notary - workflow not implmented"
                    />
                  </RadioGroup>
                </FormControl>
              </div>

              <div className={styles.buttonGroup}>
                <Button variant="contained" className={styles.closeModalButton} fullWidth onClick={() =>  navigate(HOME_PAGE)}>
                  {t('Cancel')}
                </Button>

                <Button
                  className={styles.buttonPrimary}
                  type="submit"
                  variant="contained"
                  fullWidth
                  disabled={props.isSubmitting}
                >
                  {t('Assign Role')}
                </Button>
              </div>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};
