import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Stack,
    Button,
    Container,
    Typography,
    CircularProgress,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Page from '../../components/Page/Page';
import Iconify from '../../components/Iconify/Iconify';
import useProjectService from './consumers/useProjectService';
import { useAppDeps } from '../../App';
import { USER_KEY } from '../../services/StorageService';
import { ProjectsTable } from './components/ProjectsTable';
import { CREATE_PROJECT_PAGE } from '../../router/constants/RouteNames';
import useUserService from '../User/consumers/useUserService';
import styles from './components/ProjectDetailsComponent.module.scss';
import { translate } from '../../utils/localization/translate';

export default function ProjectView() {
    const { storageService } = useAppDeps();
    const { getUserProjects, getOwnerProjects } = useProjectService();
    const { changeRole } = useUserService();
    const { t } = translate();
    const jsonUser = storageService.getItem(USER_KEY);

    const user = JSON.parse(jsonUser);

    const [projects, setProjects] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        getProjects();
    }, []);

    const handleRequestChangeRole = async () => {
        const jsonBody = {
            "roleRequest": "owner",
        }

        setIsSubmitting(true);

        try {
            await changeRole(jsonBody);
            setIsSubmitting(false);
        } catch (e) {
            setIsSubmitting(false);
        }
    }

    const getProjects = async () => {
        if (isProjectOwner()) {
            const projects = await getOwnerProjects();
            setProjects(projects);
        } else {
            const projects = await getUserProjects();
            setProjects(projects);
        }

        setIsLoading(false);
    };

    const isProjectOwner = () => {
        return user.role === 'owner';
    }

    const isUserStandard = () => {
        return user.role === 'user';
    }

    if (isLoading) {
        return <CircularProgress />;
    }

    return (
        <Page>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        {t('Projects')}
                    </Typography>
                    {
                        isProjectOwner() &&
                        <Button className={styles.buttonPrimary} variant="contained" component={RouterLink} to={CREATE_PROJECT_PAGE} startIcon={<Iconify icon="eva:plus-fill" />}>
                            {t('Create Project')}
                        </Button>
                    }
                    {
                        isUserStandard() &&
                        <LoadingButton className={styles.buttonPrimary} variant="contained" disabled={isSubmitting} onClick={handleRequestChangeRole}>
                            {isSubmitting ? <CircularProgress /> : t('Request To Become Owner')}
                        </LoadingButton>
                    }
                </Stack>

                <ProjectsTable projects={projects} />
            </Container>
        </Page>
    );
}
