import { BASE_URL } from "../config";
import ApiService from "./ApiService";
import StorageService, { JWT_TOKEN_KEY } from "./StorageService";

export class FileUploadService {
    constructor() {
        this.apiService = ApiService.getInstance();
        this.storageService = StorageService.getInstance();
        this.baseApiRoute = '/owner/projects';
    }

    async uploadPoster(payload) {
        const accessToken = this.storageService.getItem(JWT_TOKEN_KEY);
        const response = await this.apiService.postNoConfig(`${BASE_URL}${this.baseApiRoute}/uploadPoster`, payload, {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "multipart/form-data",
            }
        });

        return response;
    }

    async getPoster(address) {
        const response = await this.apiService.get(`${BASE_URL}/projects/getPoster?projectAddress=${address}`);

        return response;
    }
}
