import ApiService from "./ApiService";
import StorageService, { JWT_TOKEN_KEY, REFRESH_TOKEN_KEY, USER_KEY } from "./StorageService";
import { BASE_URL } from '../config';

let instance = null;

class AuthService {
    constructor() {
        this.storageService = StorageService.getInstance();
        this.apiService = ApiService.getInstance();
    }

    getInstance() {
        if (!instance) {
            instance = new AuthService();
        }
        return instance;
    }

    async signUp(firstName, lastName, email, password, street, additionalAddress, city, postalCode, phoneNo, accountName, iban, hasConsentToToS, hasConsentToPrivacyPolicy) {
        const payload = {
            email,
            password,
            user: {
                firstName,
                lastName,
                address: {
                    street,
                    additionalAddress,
                    city,
                    postalCode,
                },
                telephoneNumber: phoneNo,
                accountName,
                accountIban: iban,
                hasConsentToToS, 
                hasConsentToPrivacyPolicy
            }
        }

        const response = await this.apiService.postNoConfig(`${BASE_URL}/register/signup`, payload);

        return response.data;
    }

    async login(email, password) {
        const response = await this.apiService.post(`${BASE_URL}/auth/login`, {}, {
            auth: {
                username: email,
                password,
            },
        });

        this.storageService.setItem(JWT_TOKEN_KEY, response.data.accessToken);
        this.storageService.setItem(REFRESH_TOKEN_KEY, response.data.refreshToken);

        await this.getUser();

        return response.data;
    }

    async getUser() {
        const response = await this.apiService.get(`${BASE_URL}/users/me`);

        this.storageService.setItem(USER_KEY, JSON.stringify(response.data));
        return response.data;
    }

    async resendConfirmation(payload) {
        const response = await this.apiService.post(`${BASE_URL}/auth/resendConfirmationLink`, payload);
        return response.data;
    }

    async sendConfirmation(payload) {
        const response = await this.apiService.post(`${BASE_URL}/auth/sendVerificationLink`, payload);
        return response.data;
    }

    async sendResetPasswordLink(payload) {
        const response = await this.apiService.post(`${BASE_URL}/auth/sendPWResetLink`, payload);
        return response.data;
    }

    async resetPassword(payload) {
        const response = await this.apiService.post(`${BASE_URL}/auth/resetPassword`, payload);
        return response.data;
    }

    async logout() {
        const response = await this.apiService.post(`${BASE_URL}/auth/logout`);
        this.storageService.clearItem(JWT_TOKEN_KEY);
        this.storageService.clearItem(REFRESH_TOKEN_KEY);
        this.storageService.clearItem(USER_KEY);
        return response;
    }
}

export default new AuthService();