import React, { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import styles from './VerificationForm.module.scss';
import useAuth from '../../../utils/hooks/useAuth';
import { LOGIN_PAGE } from '../../../router/constants/RouteNames';
import { translate } from '../../../utils/localization/translate';

export const VerifyEmailForm = ({ email }) => {
  const { sendConfirmation } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = translate();

  const resendConfirmationLink = async () => {
    const jsonBody = {
      email: `${email}`,
    }

    setIsSubmitting(true);
    await sendConfirmation(jsonBody);
    setIsSubmitting(false);
  }

  return (
    <>
      <div className={styles.flex_col}>
        <div className={styles.flex}>
          <h3>{t('The verification link has been sent to your email.')}</h3>
          <p className={styles.paragraph}>
            {t("Your account has been created. If you don't see the email please check your spam folder or click the button bellow to resend it.")}
          </p>
          <LoadingButton className={styles.resendConfirmationButton} onClick={resendConfirmationLink} disabled={isSubmitting}>
            {isSubmitting ? <CircularProgress /> : t('Resend email')}
          </LoadingButton>
          <Link className={styles.link} to={LOGIN_PAGE} variant="body2" >
            {t('Sign in with a different account')}
          </Link>
        </div>
      </div>
    </>
  )
}
