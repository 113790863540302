import { styled } from '@mui/material/styles';

export const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '5%',
        justifyContent: 'center',
        width: '100%',
    },
}));