import React from 'react';
import {Grid, Paper, Box} from '@mui/material';
import styles from "./components/Register.module.scss";
import { RegistrationForm } from './components/RegistrationForm';

export const RegistrationView = () => {
    return (
        <Grid className={`${styles.grid} ${styles.mainContainer}`}>
            <Box className={styles.box}>
                <Paper className={styles.paper}>
                    <RegistrationForm />
                </Paper>
            </Box>
        </Grid>
    );
};
