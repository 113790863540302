import useToast from "../../../utils/hooks/useToast";
import { translate } from "../../../utils/localization/translate";
import { UserService } from "../services/User.service";

export default function useUserService() {
    const userService = new UserService();
    const toast = useToast();
    const { t } = translate();

    const editUser = async (firstName, lastName, street, additionalAddress, city, postalCode, phoneNo, accountName, iban) => {
        try {
            const response = await userService.editUser(firstName, lastName, street, additionalAddress, city, postalCode, phoneNo, accountName, iban);

            toast.show({
                message: t('Data saved.'),
                severity: 'success',
                wait: 3000,
            });

            return response.data;
        } catch (e) {
            toast.show({
                message: e.response.data.message,
                severity: 'error',
                wait: 3000,
            });

            throw e;
        }
    }

    const findUser = async (email) => {
        try {
            const reponse = await userService.findUser(email);

            // this array will always contain one object
            return reponse.data;
        } catch(e) {
            if (e.response.data.type === "VALIDATION_ERROR") {
                toast.show({
                    message: t('Please use a valid e-mail address.'),
                    severity: 'error',
                    wait: 3000,
                });    

                throw e;
            }

            toast.show({
                message: e.response.data.message,
                severity: 'error',
                wait: 3000,
            });

            throw e;
        }
    }

    const changeRole = async (payload) => {
        try {
            const reponse = await userService.changeRole(payload);

            toast.show({
                message: t('Request has been sent to the admin.'),
                severity: 'success',
                wait: 5000,
            });

            return reponse.data;
        } catch(e) {
            toast.show({
                message: e.response.data.message,
                severity: 'error',
                wait: 3000,
            });

            throw e;
        }
    }

    return {
        editUser,
        findUser,
        changeRole,
    };
}