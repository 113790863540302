import { useState } from "react";
import * as Yup from 'yup';
import Decimal from 'decimal.js-light';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Container, Grid, InputAdornment, Typography, FormControlLabel, Button, Input } from "@mui/material";
import Switch from '@mui/material/Switch';
import BuildCircleRoundedIcon from '@mui/icons-material/BuildCircleRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField } from "../../../components/Hook-Form";
import { ContentStyle, SectionStyle } from "./CreateProjectForm.styles";
import ProjectUserTable from "../../../components/ProjectUserTable/ProjectUserTable";
import FileUpload from "../../../components/FileUpload/FileUpload";
import FileList from "../../../components/FileUpload/FileList";
import styles from './CreateProjectForm.module.scss';
import useProjectService from "../consumers/useProjectService";
import useFileUpload from "../../../utils/hooks/useFileUpload";
import useUserService from "../../User/consumers/useUserService";
import Iconify from "../../../components/Iconify/Iconify";
import { SearchResults } from "../../../components/SearchBar/SearchResults";
import { translate } from "../../../utils/localization/translate";

Decimal.set({ precision: 16 });

export const CreateProjectForm = () => {
    const { createProject } = useProjectService();
    const { findUser } = useUserService();
    const { uploadPoster } = useFileUpload();
    const { t } = translate();
    const [projectUser, setProjectUser] = useState({
        address: "",
        basis: 0,
        fundingCommision: false,
        amount: 0
    })
    const [projectUserArray, setProjectUserArray] = useState([])

    const [searchQuery, setSearchQuery] = useState('');
    const [foundUsers, setFoundUsers] = useState(null);
    const [isSearching, setIsSearching] = useState(false);

    const handleOnInputChange = async (e) => {
        const searchValue = e.target.value;

        setSearchQuery(searchValue);
    }

    const onSearch = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        setIsSearching(true);
        const users = await findUser(searchQuery);

        setFoundUsers(users);
        setIsSearching(false);
    }

    const updateProjectUser = (value, attribute) => {
        if (attribute === "address") {
            setProjectUser(preVal => ({ ...preVal, address: value }))
        }
        if (attribute === "basis") {
            setProjectUser(preVal => ({ ...preVal, basis: value }))
        }
        if (attribute === "fundingCommision") {
            if (!value) {
                setProjectUser(preVal => ({ ...preVal, amount: 0, fundingCommision: value }))
            } else {
                setProjectUser(preVal => ({ ...preVal, fundingCommision: value }))
            }
        }
        if (attribute === "amount") {
            setProjectUser(preVal => ({ ...preVal, amount: value }))
        }
        if (attribute === "reset") {
            setProjectUser(() => ({
                address: "",
                basis: 0,
                fundingCommision: false,
                amount: 0
            }))
        }
    }

    const removeProjectUser = (index) => {
        setProjectUserArray(preVal => preVal.filter((val, i) => i !== index))
    }


    const addProjectUser = () => {
        if (projectUser.address.trim() === "" || projectUser.basis === 0 || (projectUser.fundingCommision && projectUser.amount === 0)) {
            return null
        }

        const user = JSON.parse(JSON.stringify(projectUser))

        if (user.fundingCommision === true) {
            user.fundingCommision = 1
        }
        else if (user.fundingCommision === false) {
            user.fundingCommision = 0
        }

        // user.basis = new Decimal(user.basis).div(100).times(10000).toDecimalPlaces(0).toNumber();
        // user.amount = parseInt(user.amount, 10);
        user.basis = parseInt(new Decimal(user.basis).toDecimalPlaces(0), 10);
        user.amount = parseInt((new Decimal(user.amount).times(100)), 10);

        setProjectUserArray(preVal => [...preVal, user])
        updateProjectUser(null, "reset")
    }

    // Poster Upload and Remove
    const [poster, setPoster] = useState(null);

    const CreateProjectSchema = Yup.object().shape({
        projectName: Yup.string().required(t('Project Name required')),
    });

    const defaultValues = {
        projectName: '',
    };

    const methods = useForm({
        resolver: yupResolver(CreateProjectSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        reset,
        formState: { isSubmitting },
    } = methods;

    /**
   * Form Submission for Creating a project 
   */
    const onSubmit = async (data) => {
        const jsonBody = {
            projectName: data.projectName,
            users: projectUserArray.map(Object.values)
        }

        const response = await createProject(jsonBody);

        if (poster) {
            const formData = new FormData();

            formData.append("projectAddress", response.projectAddress);
            formData.append("file", poster, poster.name);

            await uploadPoster(formData);
        }

        reset();
        setProjectUserArray([]);
    };

    const handleFileUpload = (file) => {
        setPoster(file);
    }

    return (
        <>
            <FormProvider autoComplete="on" onSubmit={(e) => onSearch(e)} methods={{onSearch}}>
                <Container className={`${styles.container} ${styles.searchFormContainer}`}>
                    <div className={styles.searchBar}>
                        <Input
                            autoFocus
                            fullWidth
                            disableUnderline
                            placeholder={`${t('Search users')}…`}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                                </InputAdornment>
                            }
                            name="email"
                            sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
                            onChange={(e) => handleOnInputChange(e)}
                        />
                        <Button variant="contained" type="submit">
                            {t('Search')}
                        </Button>
                    </div>

                    {!isSearching && foundUsers &&
                        <SearchResults results={foundUsers} />
                    }
                </Container>
            </FormProvider>
            <FormProvider autoComplete="on" methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Container className={styles.container}>
                    <ContentStyle className={styles.contentStyle}>
                        <Grid className={styles.formContainer} container spacing="1" alignItems="center">
                            <div className={styles.gutteredRow}>
                                <RHFTextField className={styles.inputField}
                                    name="projectName"
                                    label={t('Project Name')}
                                    placeholder={t('Enter Project name')}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <BuildCircleRoundedIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>

                            <Typography variant="contained" align="left" sx={{ color: 'text.primary', mt: 2, mb: -2, fontWeight: 'bold' }}>
                                {t('Add members')}
                            </Typography>
                            <SectionStyle className={styles.userSection}>
                                <div className={styles.inputRow}>
                                    <RHFTextField style={{ minWidth: '70%', marginTop: 10, marginBottom: 10, padding: 2 }}
                                        name="address"
                                        label={t('Address')}
                                        placeholder={t('Enter User Address')}
                                        value={projectUser.address}
                                        onChange={(e) => updateProjectUser(e.target.value, "address")}
                                        inputProps={{ required: false }}
                                    />
                                    <RHFTextField style={{ marginTop: 10, marginBottom: 10, padding: 2 }}
                                        name="basis"
                                        label="bps"
                                        placeholder={t('Basis')}
                                        type="number"
                                        value={projectUser.basis === 0 ? "" : projectUser.basis}
                                        onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                                        onChange={(e) => updateProjectUser(e.target.value, "basis")}
                                        inputProps={{ required: false }}
                                    />
                                </div>
                                <div className={styles.inputRow}>
                                    <FormControlLabel style={{ marginTop: 10, marginBottom: 10, padding: 2, whiteSpace: 'nowrap' }}
                                        control={<Switch checked={projectUser.fundingCommision} onChange={(e) => {
                                            updateProjectUser(e.target.checked, "fundingCommision")
                                        }}
                                            inputProps={{ 'aria-label': 'controlled' }} />}
                                        label={t('Funding Commision/Invest')}
                                        labelPlacement="end"
                                    />
                                    <RHFTextField className={styles.inputFieldSm} style={{ marginTop: 10, marginBottom: 10, marginLeft: 10, padding: 2 }}
                                        name="amount" value={projectUser.amount === 0 ? "" : projectUser.amount}
                                        onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                                        onChange={(e) => updateProjectUser(e.target.value, "amount")}
                                        disabled={!projectUser.fundingCommision}
                                        placeholder="€"
                                        type="number"
                                        label={t('Amount')}
                                    />
                                </div>
                                <Button
                                    className={styles.addUserButton}
                                    onClick={addProjectUser}
                                >
                                    <AddRoundedIcon /> {t('Add')}
                                </Button>
                            </SectionStyle>

                            <ProjectUserTable projectUser={projectUserArray} removeProjectUser={removeProjectUser} />

                            <div style={{ marginLeft: '2%', marginTop: 20 }} className={styles.fileUploadContainer}>
                                <FileUpload file={poster} setFile={handleFileUpload} />
                                <FileList file={poster} setFile={handleFileUpload} />
                            </div>
                        </Grid>

                        <LoadingButton style={{ marginTop: 18, whiteSpace: 'nowrap', backgroundColor: '#cb1517' }} size="large" type="submit" variant="contained" loading={isSubmitting}>
                            {t('Create Project')}
                        </LoadingButton>
                    </ContentStyle>
                </Container>
            </FormProvider>
        </>
    );
}