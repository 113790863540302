import { Formik, Form, Field, ErrorMessage } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { TextField, Button, Grid, Typography, FormControlLabel, Radio, RadioGroup, FormControl } from '@mui/material';
import styles from '../../Login/components/Login.module.scss';
import useProjectService from '../consumers/useProjectService';
import { translate } from '../../../utils/localization/translate';

export const AddRevenueForm = ({ projectId, closeForm, refetchData }) => {

    const [revenueType, setRevenueType] = useState(0);
    const { addRevenue } = useProjectService();
    const { t } = translate();

    const initialValues = {
        revenue: '',
    };


    /**
     * Form Validation
     */
    const validationSchema = Yup.object().shape({
        revenue: Yup.number().transform(value => (Number.isNaN(value) ? undefined : value)).required("can not be empty")
    });

    /**
    * Gets called on Sign in
    */
    const handleOnSubmit = async (values, props) => {
        // We are multiplying the value by a 100 to get the value in cents.
        const jsonBody = {
            "projectId": projectId,
            "revenue": parseFloat(values.revenue) * 100,
            "revenueType": parseInt(revenueType, 10)
        }

        await addRevenue(jsonBody);

        closeForm();
        refetchData();

        props.resetForm();
        props.setSubmitting(false);
    };

    /**
     * Revenue Type Handler
     */

    const handleChange = (event) => {
        setRevenueType(event.target.value);
    }

    return (
        <div className={styles.root}>
            <Formik initialValues={initialValues} onSubmit={handleOnSubmit} validationSchema={validationSchema}>
                {(props) => (
                    <Form>
                        <Grid className={styles.formContainer} container spacing="1" alignItems="center">
                            <Typography variant="contained" align="left" sx={{ color: 'text.primary', mt: 2, mb: -2, fontWeight: 'bold' }}>
                                {t('Add Revenue')}
                            </Typography>
                            <Field as={TextField} className={styles.textField}
                                name="revenue"
                                label={t('Revenue')}
                                placeholder={t('Enter revenue (€)')}
                                fullWidth required
                                helperText={<b className={styles.errorText}> <ErrorMessage name="quota" /> </b>}
                            />
                            
                            <Typography variant="contained" align="left" sx={{ color: 'text.primary', mt: 2, mb: -2, fontWeight: 'bold' }}>
                                {t('Revenue Type')}
                            </Typography>

                            <div className={styles.selectGroup}>
                            <FormControl sx={{display:'flex', justifyContent:'flex-start'}}>
                                <RadioGroup
                                    aria-labelledby="revenueType"
                                    name="revenueType"
                                    value={revenueType}
                                    defaultValue= '0'
                                    onChange={handleChange}
                                >
                                    <FormControlLabel value={0} control={<Radio sx={{'&, &.Mui-checked': {color: '#cb1517'}}} />} label='Ticket Sales' />
                                    <FormControlLabel value={1} control={<Radio sx={{'&, &.Mui-checked': {color: '#cb1517'}}} />} label='Streaming Rights' />
                                    <FormControlLabel value={2} control={<Radio sx={{'&, &.Mui-checked': {color: '#cb1517'}}} />} label='Broadcasting Rights' />
                                </RadioGroup>
                            </FormControl>
                            </div>

                            <div className={styles.buttonGroup}>
                                <Button variant="contained" className={styles.closeModalButton} fullWidth onClick={() => closeForm()}>
                                    {t('Cancel')}
                                </Button>

                                <Button className={styles.buttonPrimary} type="submit" variant="contained" fullWidth disabled={props.isSubmitting}>
                                    {props.isSubmitting ? t('Loading') : t('Add Revenue')}
                                </Button>
                            </div>

                        </Grid>
                    </Form>
                )}
            </Formik>
        </div>
    );
}