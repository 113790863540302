import { styled } from '@mui/material/styles';
import { Card } from '@mui/material';

export const ContentStyle = styled(Card)(({ theme }) => ({
    maxWidth: '100%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(8, 0),
}));

export const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    margin: theme.spacing(3, 3, 3, 3),
}));