import { Formik, Form, Field, ErrorMessage } from 'formik';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { TextField, Button, InputAdornment } from '@mui/material';
import EmailSharpIcon from '@mui/icons-material/EmailSharp';
import LockIcon from '@mui/icons-material/Lock';
import styles from './Login.module.scss';
import { EMAIL_VERIFICATION_PAGE, FORGOT_PASSWORD_PAGE, HOME_PAGE, REGISTRATION_PAGE } from '../../../router/constants/RouteNames';
import useAuth from '../../../utils/hooks/useAuth';
import { translate } from '../../../utils/localization/translate';
import successSound from '../../../assets/audio/KAAATSCHING_SOUND_1st.mov';
import { useAppDeps } from '../../../App';
import { USER_SETTINGS } from '../../../services/StorageService';

export const LoginForm = () => {
    const { login } = useAuth();
    const { t } = translate();
    const [audio] = useState(new Audio(successSound));
    const [playing, setPlaying] = useState(false);
    const { storageService } = useAppDeps();
    const userSettings = JSON.parse(storageService.getItemFromLocalStorage(USER_SETTINGS));
    const isAudioOn = userSettings ? userSettings.audio : true;

    const toggleAudio = () => setPlaying(!playing);

    const navigate = useNavigate();

    const initialValues = {
        email: '',
        password: '',
        remember: false,
    };

    useEffect(() => {
        if (playing) {
            audio.volume = 0.2;
            audio.play();
            return;
        }

        audio.pause();
    }, [playing]);

    /**
     * Form Validation
     */
    const validationSchema = Yup.object().shape({
        email: Yup.string().email(t('Please provide valid Email')).required(t('Required')),
        password: Yup.string().required(t('Required')),
    });

    /**
    * Gets called on Sign in
    */
    const onSubmit = async (values, props) => {
        try {
            await login(values.email, values.password);

            if (isAudioOn) {
                toggleAudio();
                setTimeout(() => {
                    toggleAudio();

                    navigate(HOME_PAGE, { replace: true });

                    props.resetForm();
                    props.setSubmitting(false);
                }, 1000);
            } else {
                navigate(HOME_PAGE, { replace: true });

                props.resetForm();
                props.setSubmitting(false);
            }
        } catch (e) {
            if (e.response.data.message === 'User is not Verified yet') {
                navigate(EMAIL_VERIFICATION_PAGE.replace(':email', values.email));
            }
        }
    };

    return (
        <div className={styles.root}>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                {(props) => (
                    <Form>
                        <Field as={TextField} className={styles.textField}
                            name="email"
                            label={t('Email')}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EmailSharpIcon />
                                    </InputAdornment>
                                ),
                            }}
                            placeholder={t('Enter Email')}
                            fullWidth required
                            helperText={<b className={styles.errorText}> <ErrorMessage name="email" /> </b>}
                        />

                        <Field as={TextField} className={styles.textField}
                            name="password"
                            label={t('Password')}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LockIcon />
                                    </InputAdornment>
                                ),
                            }}
                            placeholder={t('Enter Password')}
                            fullWidth required
                            helperText={<b className={styles.errorText}> <ErrorMessage name="password" /> </b>}
                            type="password"
                        />
                        <Link to={FORGOT_PASSWORD_PAGE} className={styles.link}>
                            {t('Forgot password?')}
                        </Link>

                        <Button type="submit" variant="contained" className={styles.loginButtonContainer} fullWidth disabled={props.isSubmitting}>
                            {props.isSubmitting ? t('Loading') : t('Sign In')}
                        </Button>
                    </Form>
                )}
            </Formik>
            <Link to={REGISTRATION_PAGE} className={styles.link}>
                {t('Do you want to register? Sign Up')}
            </Link>
        </div>
    );
}