import { useEffect, useState } from 'react';
import useFileUpload from '../../utils/hooks/useFileUpload';
import { translate } from '../../utils/localization/translate';
import styles from './PosterView.module.scss';

export const PosterView = ({ project }) => {
    const { getPoster } = useFileUpload();
    const [poster, setPoster] = useState('');
    const { t } = translate();

    useEffect(() => {
        fetchPoster();
    }, []);

    const fetchPoster = async () => {
        const poster = await getPoster(project.projectaddress);

        setPoster(poster);
    }

    const getStatusClass = () => {
        switch (project.projectDetails.projectStage) {
            case 0:
                return styles.inProduction;
            case 1:
            default:
                return styles.finished;
        }
    }

    const getName = (name) => {
        if (name.length < 12) {
            return name;
        }

        return `${name.substring(0, 12)}...`;
    }

    if (!project.projectDetails.userTO) {
        return;
    }

    return (
        <div className={styles.posterView}>
            <div className={styles.imageContainer} style={{
                backgroundImage: `url("${poster}")`
            }} />
            <div className={styles.header}>
                <div className={styles.projectName}>{getName(project.projectName)}</div>
                <div className={`${styles.status} ${getStatusClass()}`}>{project.projectDetails.projectStage === 1 ? t('Finished') : t('In production')}</div>
            </div>
            <div className={styles.info}>
                <div className={styles.item}>
                    <span>{t('Pending Revenue')}:</span>
                    <span>{parseFloat(project.projectDetails.userTO.pendingRevenue).toFixed(2).concat(" € ")}</span>
                </div>
            </div>
        </div>
    );
}