import React, { createContext, useContext } from 'react';

export default function dependencyInjection({ services }) {
  const DependencyContext = createContext(services);

  function DependencyProvider({ children }) {
    return (
      <DependencyContext.Provider value={services}>
        {children}
      </DependencyContext.Provider>
    );
  }

  function useDependency() {
    return useContext(DependencyContext);
  }

  return [DependencyProvider, useDependency];
}
