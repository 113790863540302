import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Stack, Container, Typography, CircularProgress, Button } from '@mui/material';
import Page from '../../components/Page/Page';
import useProjectService from './consumers/useProjectService';
import Iconify from '../../components/Iconify/Iconify';
import { ProjectDetailsComponent } from './components/ProjectDetailsComponent';
import { NotaryUserTable } from './components/NotaryUserTable';
import { useAppDeps } from '../../App';
import { USER_KEY } from '../../services/StorageService';
import { AddRevenueForm } from './components/AddRevenueForm';
import { PictureLockWarningForm } from './components/PictureLockWarningForm';
import styles from './components/ProjectDetailsComponent.module.scss';
import { translate } from '../../utils/localization/translate';

export const ProjectDetailsView = () => {
  const { id } = useParams();
  const { getProjectById, getUsersForProjectNotary, getNotaryProjectById} = useProjectService();
  const { t } = translate();
  const [project, setProject] = useState(null);
  const [userList, setUserList] = useState(null);
  const { storageService } = useAppDeps();
  const user = JSON.parse(storageService.getItem(USER_KEY));
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [id]);

  const getData = async () => {
    setIsLoading(true);

    if (user.role === 'notary') {
      await getProjectUsers();
      await getProjectNotary();
      setIsLoading(false);
    } else {
      await getProject();
      setIsLoading(false);
    }
  };

  const getProject = async () => {
    const project = await getProjectById(id);
    
    setProject(project);
  };

  const getProjectNotary = async () => {
    const project = await getNotaryProjectById(id);
    
    setProject(project);
  };

  const getProjectUsers = async () => {
    const users = await getUsersForProjectNotary(id);

    setUserList(users);
  };

  const isPictureLockSet = () => {
    return project.projectDetails.projectStage === 1;
  };

  const isUserProjectOwner = () => {
    return user.role === 'owner';
  };

  const isUserNotary = () => {
    return user.role === 'notary';
  };

  const getPageConent = () => {
    switch (user.role) {
      case 'notary':
        return <NotaryUserTable project={project} projectId={id} userList={userList} refetchData={getData} />;
      default:
        return <ProjectDetailsComponent project={project} user={user} />;
    }
  };

  const handleBackgroundClick = (e) => {
    const modal = document.getElementById('modal');
    if (e.target === modal) {
      setIsModalOpen(false);
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }
 
  return (
    <Page>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {project?.projectName || id}
          </Typography>
          {isUserProjectOwner() && project.projectDetails  && (
            <Button
              className={styles.buttonPrimary}
              variant="contained"
              disabled={isPictureLockSet()}
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => setIsModalOpen(true)}
            >
              {t('Set Picture Lock')}
            </Button>
          )}

          {isUserNotary() && (
            <Button
              className={styles.buttonPrimary}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => setIsModalOpen(true)}
            >
              {t('Add Revenue')}
            </Button>
          )}
        </Stack>
        {getPageConent()}
      </Container>
      {isUserNotary() && isModalOpen && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div className={styles.modal} id="modal" onClick={(e) => handleBackgroundClick(e)}>
          <div className={styles.modalContainer}>
            <AddRevenueForm projectId={id} closeForm={() => setIsModalOpen(false)} refetchData={() => getData()} />
          </div>
        </div>
      )}
      {isUserProjectOwner() && isModalOpen && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div className={styles.modal} id="modal" onClick={(e) => handleBackgroundClick(e)}>
          <div className={styles.modalContainer}>
            {
              <PictureLockWarningForm id={id} closeForm={() => setIsModalOpen(false)} refetchData={() =>  (getData)} />
            }
          </div>
        </div>
      )}
    </Page>
  );
};
