import React, { useRef, useState } from 'react';
// material
import { alpha } from '@mui/material/styles';
import { Box, MenuItem, Stack, IconButton } from '@mui/material';
import MenuPopover from '../MenuPopover/MenuPopover';
import { useAppContextStore, useAppDispatch } from '../../context/App/App.context';
import { setLanguageAction } from '../../context/App/App.actions';
import { translate } from '../../utils/localization/translate';
import { useAppDeps } from '../../App';

export default function LanguagePopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { language } = useAppContextStore();
  const { storageService } = useAppDeps();
  const { t } = translate();

  const LANGS = [
    {
      value: 'en',
      label: t('English'),
      icon: '/static/icons/ic_flag_us.svg',
    },
    {
      value: 'fr',
      label: t('French'),
      icon: '/static/icons/ic_flag_fr.svg',
    },
    {
      value: 'de',
      label: t('German'),
      icon: '/static/icons/ic_flag_de.svg',
    },
    {
      value: 'pt',
      label: t('Portuguese'),
      icon: '/static/icons/ic_flag_pt.svg',
    },
    {
      value: 'es',
      label: t('Spanish'),
      icon: '/static/icons/ic_flag_es.svg',
    },
  ];

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLanguageChange = (value) => {
    dispatch(setLanguageAction(value));
    storageService.setItemToLocalStorage('language', value);
    setOpen(false);
  }

  const getSelectedLanguageIcon = () => {
    const selectedLanguage = LANGS.find(item => item.value === language);
    return selectedLanguage.icon;
  }

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <img src={getSelectedLanguageIcon()} alt='language' />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          {LANGS.map((option) => (
            <MenuItem key={option.value} selected={option.value === language} onClick={() => handleLanguageChange(option.value)}>
              <Box component="img" alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} />
              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
