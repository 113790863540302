export const pt = {
    Home: 'Início',
    Projects: 'Projetos',
    'Create Project': 'Criar projeto',
    Search: 'Pesquisar',
    Profile: 'Perfil',
    Settings: 'Definições',
    Logout: 'Saír',
    Hi: 'Olá',
    'Latest projects': 'Últimos projetos',
    Finished: 'Projetos terminados',
    'In production': 'Em produção',
    'Pending Revenue': 'Receitas pendentes',
    'Projects Revenue': 'Receitas dos Projetos',
    'Project Breakdown': 'Repartição do projecto',
    'Project details': 'Detalhes do projeto',
    'Your revenue for this project': 'A sua receita para este projeto',
    'Project status': 'Estado do projeto',
    'Total project revenue': 'Receitas totais do projeto',
    'Project address': 'Endereço do projeto',
    'Pending revenue': 'Receitas pendentes',
    'Initial funding': 'Financiamento inicial',
    'Payed out': 'Pago',
    Quota: 'Quota',
    'Set Picture Lock': 'Fixação de imagem',
    'Project members': 'Membros do projeto',
    'Add Member': 'Adicionar membro',
    Username: 'Nome de utilizador',
    Address: 'Morada',
    Percentage: 'Percentagem',
    'Update Quota': 'Atualizar quota',
    Title: 'Título',
    Status: 'Estado',
    Payout: 'Pagamento',
    'Add Reveneue': 'Adicionar receita',
    Revenue: 'Receita',
    Cancel: 'Cancelar',
    'First Name': 'Nome próprio',
    'Enter First Name': 'Introduzir nome próprio',
    'Last Name': 'Apelido',
    'Enter Last Name': 'Introduzir apelido',
    'Street, Address': 'Rua, morada',
    'Enter Street': 'Introduzur rua',
    'Additional Address': 'Morada adicional',
    'Additional address eg Apartment and floor': 'Morada adicional ex. apartamento e piso',
    City: 'Cidade',
    'Enter City': 'Introduzir cidade',
    Postcode: 'Código postal',
    'Postal Code': 'Código postal',
    'Phone No': 'Número de telefone',
    'Enter Phone No': 'Introduzir número de telefone',
    'Phone Number must be at least 10 characters': 'O número de telefone deve ter pelo menos 10 caracteres',
    'Account Name': 'Nome da conta',
    'Enter Account Name': 'Introduzir nome da conta',
    IBAN: 'IBAN',
    'Enter IBAN': 'Introduzir IBAN',
    'IBAN must be at least 15 characters': 'IBAN deve ter pelo menos 15 caracteres',
    'Search projects': 'Pesquisar projetos',
    'Request To Become Owner': 'Pedido para se tornar proprietário',
    'No finished projects': 'Não há projectos terminados',
    'Filter list': 'Lista de Filtros',
    Delete: 'Eliminar',
    'Copy to Clipboard': 'Copiar para área de trânsferencia',
    Copied: 'Copiado',
    'Enter revenue (€)': 'Introduzir receita (€)',
    Loading: 'carregar',
    Required: 'necessário',
    'Enter quota': 'Introduzir quota',
    'Search users': 'Pesquisar utilisadores',
    'Enter User Address': 'Introduzir morada de utilizador',
    Basis: 'Base',
    'Funding Commision/Invest': 'Comissão de financiamento',
    Amount: 'Montante',
    'Add members': 'Adicionar membros',
    'CSF name': 'CSF nome',
    'CSF %': 'CSF %',
    'Project Name required': 'Nome do projeto necessário',
    'Project Name': 'Nome do projeto',
    'Enter Project name': 'Introduzir nome do projeto',
    Add: 'Adicionar',
    'File size should not be larger than 3MB': 'O tamanho do ficheiro não deve ser maior do que 3 mb',
    'Allowed file types are: .jpg, .jpeg, .png and .tiff':
      'Os tipos de ficheiro permitidos são: .jpg, .jpeg, .png e .tiff',
    'Upload Poster': 'Carregar poster',
    'Your info': 'Os seus dados',
    Save: 'Guardar',
    Login: 'Iniciar sessão',
    'Please provide valid Email': 'Iniciar sessãoPor favor forneça um endereço de e-mail válido',
    Email: 'Email',
    'Enter Email': 'Introduzir Email',
    Password: 'Palavra-passe',
    'Enter Password': 'Introduzir Palavra-passe',
    'Forgot password?': 'Esqueceu-se da palavra-passe?',
    'Sign In': 'Iniciar sessão',
    'Do you want to register? Sign Up': 'Quer registrar-se? Registrar',
    'New password': 'Nova palavra-passe',
    'Passwords do not match': 'As palavras-passe não correspondem',
    'Confirm Password': 'Confirmar palavra-passe',
    'Already have an account? Sign in': 'Já tem uma conta? Iniciar sessão',
    'Reset password': 'Redefinir palavra-passe',
    'Password not strong enough': 'A palavra-passe não é suficientemente forte',
    'Sign Up': 'Registrar',
    'The verification link has been sent to your email.': 'O link de verificação foi enviado para o seu e-mail',
    "Your account has been created. If you don't see the email please check your spam folder or click the button bellow to resend it.":
      'A sua conta foi criada. Se não vir o e-mail, verifique a sua pasta de spam ou clique no botão abaixo para a reen',
    'Resend email': 'Reenviar Email',
    'Sign in with a different account': 'Iniciar sessão com outra conta',
    'The confirmation link has been expired. Use resend Confirmation to get new verification link':
      'O link de confirmação expirou. Utilizar reenviar confirmação para obter novo link de verificação',
    'Resend Confirmation': 'Reenviar confirmação',
    'Account verification is in process': 'A verificação da conta está em curso',
    'Forgot password': 'Esqueci-me a palavra-passe',
    'Confirmation Link has Expired, Use resend Confirmation to get new link':
      'O link de confirmação expirou, Use a confirmação de reenvio para obter novo link',
    'Your account has been verified': 'A sua conta foi verificada',
    'Data saved.': 'Dados guardados.',
    'Request has been sent to the admin.': 'O pedido foi enviado para a administração.',
    'Project created.': 'Projeto criado.',
    'Revenue added.': 'Receita adicionada',
    'Picture Lock set.': 'Conjunto de bloqueio de imagem',
    Success: 'Sucesso',
    'Quota updated.': 'Contingente atualizado.',
    'Confirmation Link has been sent. Dont forget to check spam folder':
      'Foi enviado um link de confirmação. Não se esqueça de verificar a pasta de spam',
    'Reset Link has been sent. Dont forget to check spam folder':
      'Foi enviado um link de reinicialização. Não se esqueça de verificar a pasta de spam',
    'Password has been reset.': 'A palavra-passe foi reiniciada',
    'Add Revenue': 'Adicionar receita',
    Portuguese: 'Português',
    French: 'Francês',
    Spanish: 'Espanhol',
    German: 'Alemão',
    English: 'Inglês',
    'Rows per page:': 'Filas por página:',
    'Please use a valid e-mail address.': 'Please use a valid e-mail address.',
    'Play sound on Sign In': 'Tocar som ao entrar',
    'Enter Quota in bps': 'Digite a cota em bps' 
  };