import { Grid, Paper, Box } from '@mui/material';
import { LoginForm } from "./components/LoginForm";
import styles from './components/Login.module.scss';
import Logo from '../../components/Logo/Logo';
import { translate } from '../../utils/localization/translate';

export const LoginVIew = () => {
    const { t } = translate();

    return(
        <Grid className={styles.mainContainer}>
            <Box className={styles.box}>
                <Paper className={styles.paper}>
                    <Grid className={styles.grid}>
                        <Logo disabledLink={true} />
                    </Grid>
                    <div>
                        <p className={styles.heading}>{t('Login')}</p>
                    </div>
                    <LoginForm />
                </Paper>
            </Box>
        </Grid>
    );
}