export const DASHBOARD = '/';
export const HOME_PAGE = '/home';
export const USER_PAGE = '/user';
export const SETTINGS = '/settings';
export const CREATE_PROJECT_PAGE = '/create-project';
export const LOGIN_PAGE = '/login';
export const REGISTRATION_PAGE = '/register';
export const FORGOT_PASSWORD_PAGE = '/forgot-password';
export const NEW_PASSWORD_PAGE = '/reset/:resetToken';
export const VERIFICATION_PAGE = '/verify/:confirmationCode';
export const PROJECTS_PAGE = '/projects';
export const PROJECT_DETAILS_PAGE = '/project-details/:id';
export const EMAIL_VERIFICATION_PAGE = '/email-verification/:email';
export const PRIVACY_POLICY = '/privacy-policy';
export const TERMS_OF_SERVICE = '/terms-of-service';
export const ROLE_CHANGE_PAGE = '/assign/role/:parcId';