import { CardHeader, CircularProgress, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import BarChart from "../../../components/BarChart/BarChart";
import { translate } from "../../../utils/localization/translate";
import ProjectPopover from "./ProjectPopover";
import styles from './ProjectListComponent.module.scss';
import useProjectService from "../../Project/consumers/useProjectService";
import { useAppContextStore, useAppDispatch } from "../../../context/App/App.context";
import { setBreakdownChartDataAction } from "../../../context/App/App.actions";

export const ProjectBreakdownComponent = () => {
    const { t } = translate();
    const { breakdownChartData } = useAppContextStore();
    const dispatch = useAppDispatch();
    const { getHistoricChartData } = useProjectService();
    const [isLoading, setIsLoading] = useState(true);
    const [activeProject, setActiveProject] = useState(breakdownChartData[0]);
    const [hasRendered, setHasRendered] = useState(false);
    const [intervalIds, setIntervalIds] = useState([]);

    useEffect(() => {

        getData();
        if(!hasRendered)
        {
            const intervalId = setInterval(() => {
                getData();
            }, 86400000);

            setIntervalIds([...intervalIds, intervalId]);
        }

        setHasRendered(true);

        return () => {
            clearInterval(...intervalIds);
        };
        
    }, [hasRendered]);

    const getData = async () => {
        setIsLoading(true);

        const response = await getHistoricChartData();
        if (response.length > 0) {
            dispatch(setBreakdownChartDataAction(response));
            setActiveProject(response[0]);
        }

        setIsLoading(false);
    }

    const getChartLabels = () => {
        return activeProject.revenues.map(item => new Date(item.timeStamp._seconds * 1000).toLocaleDateString('en'));
    }

    const getChartData = () => {
        return activeProject.revenues.map(item => parseFloat(item.revenue, 10).toFixed(3));
    }

    const onDropdownClick = (projectAddress) => {
        const newActiveProject = breakdownChartData.find(item => item.id === projectAddress);
        setActiveProject(newActiveProject);
    }

    const mapProjectsToDropDown = () => {
        const mappedArray = [];
        breakdownChartData.forEach(item => {
            mappedArray.push(
                {
                    name: item.projectName,
                    address: item.id,
                }
            );
        });

        return mappedArray;
    }

    return (
        <Grid className={styles.chartContainer} item width='100%'>
            <div className={styles.chartHeader}>
                <CardHeader title={t("Project Breakdown")} style={{padding: '0'}} />
                {
                    !isLoading &&
                    <ProjectPopover
                        projects={mapProjectsToDropDown()}
                        onClick={(address) => onDropdownClick(address)}
                        activeProject={activeProject}
                    />
                }
            </div>
            {isLoading
                ? <CircularProgress />
                : <BarChart
                    style={{ boxShadow: 'none' }}
                    chartLabels={getChartLabels()}
                    chartData={[
                        {
                            name: activeProject.projectName,
                            type: 'column',
                            fill: 'solid',
                            data: getChartData(),
                        },
                    ]}
                />
            }
        </Grid>
    );
}