import React from 'react';
import { Grid, Container, Typography } from '@mui/material';
import Page from '../../components/Page/Page';
import { CreateProjectForm } from './components/CreateProjectForm';
import { ProjectService } from './services/Project.service';
import dependencyInjection from '../../utils/dependencyInjection';
import { RootStyle } from './CreateProjectView.styles';
import styles from './components/CreateProjectForm.module.scss';
import { translate } from '../../utils/localization/translate';

const projectService = new ProjectService();

const [CreateProjectDependencyProvider, useCreateProjectDeps] = dependencyInjection({
  services: {
    projectService,
  },
});

export default function CreateProjectScreen() {
  const { t } = translate();

  return (
    <CreateProjectDependencyProvider>
      <Page>
        <Container>
          <Typography variant="h4">{t('Create Project')}</Typography>
          <Grid className={styles.grid} container spacing={3}>
            <RootStyle className={styles.rootStyle}>
              <CreateProjectForm />
            </RootStyle>
          </Grid>
        </Container>
      </Page>
    </CreateProjectDependencyProvider>
  );
}

export { useCreateProjectDeps };