import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import { Toast } from '../../components/Toast/Toast';

export default function useToast(props) {
  const show = ({ message, severity, wait, position = { horizontal: 'center', vertical: 'top' } }) => {
    if (!wait) {
      props = {
        ...props,
        onClose: () => unMountToast(),
      };
    }
    ReactDOM.render(
      <Toast {...props} message={message} severity={severity} anchorOrigin={position} open />,
      document.getElementById('toast'),
    );

    if (wait) {
      (async () => {
        await unMountToast(wait);
      })();
    }
  };

  async function unMountToast(wait) {
    await new Promise(() =>
      setTimeout(() => {
        ReactDOM.unmountComponentAtNode(document.getElementById('toast'));
      }, wait || 0),
    );
  }

  const memoShow = useRef(show);

  return {
    show: memoShow.current,
  };
}

export async function showToast(wait, message) {
  ReactDOM.render(
    <Toast message={message} severity='error' anchorOrigin={{ horizontal: 'center', vertical: 'top' }} open />,
    document.getElementById('toast'),
  );

  await new Promise(() =>
    setTimeout(() => {
      ReactDOM.unmountComponentAtNode(document.getElementById('toast'));
    }, wait),
  );
}
