import { Box, Grid, Paper } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { AssignRoleForm } from './components/assignRoleForm';
// import { AdminService } from './services/Admin.Service';
import styles from "./components/assignRoleForm.module.scss";
import Logo from '../../components/Logo/Logo';

// ----------------------------------------------------------------------
// const adminService = new AdminService();

export default function VerificationView() {
  const params = useParams();
  return (
        <Grid className={styles.grid}>
            <Box className={styles.box}>
                <Paper className={styles.paper}>
                    <Grid className={styles.logoGrid}>
                        <Logo disabledLink={true} />
                    </Grid>
                    <AssignRoleForm parcId = {params.parcId} />
                </Paper>
            </Box>
        </Grid>
  );
}