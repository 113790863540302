import useToast from "../../../utils/hooks/useToast";
import { translate } from "../../../utils/localization/translate";
import { AdminService } from "../services/Admin.Service";

export default function useAdminService() {
    const adminService = new AdminService();
    const toast = useToast();
    const { t } = translate();

    const changeUserRole = async (payload) => {
        try {
            const response = await adminService.grantRole(payload);
           
            if(response.data.message === "User does not exist")
            {
                toast.show({
                    message: t('User was not found'),
                    severity: 'error',
                    wait: 5000,
                });
                return false;  
            }
            
            if(response.data === "User is not Verified yet")
            {
                toast.show({
                    message: t('Oops. Something went wrong, user may not be verified'),
                    severity: 'error',
                    wait: 5000,
                });
                return false;  
            }

            if(response.data === "Cannot read properties of null (reading 'parcId')")
            {
                toast.show({
                    message: t('You need to be logged in to perform this action'),
                    severity: 'error',
                    wait: 5000,
                });
                return false;  
            }

            toast.show({
                message: t('user role has been updated'),
                severity: 'success',
                wait: 5000,
            });
            

        } catch (e) {
            if(e.response.data.message === "Cannot read properties of null (reading 'parcId')") {
                toast.show({
                    message: "You need to be logged in to perform this action",
                    severity: 'error',
                    wait: 3000,
                });
    
                throw e;
            }
            else {
                toast.show({
                    message: e.response.data.message,
                    severity: 'error',
                    wait: 3000,
                });
    
                throw e;
            }

        }
    }

    return {
        changeUserRole,
    };
}