export const fr = {
    'Home': 'Menu principal',
    'Projects': 'Projets',
    'Create Project': 'Créer un nouveau projet',
    'Search': 'Rechercher',
    'Profile': 'Profil',
    'Settings': 'Réglages',
    'Logout': 'Se déconnecter',
    'Hi': 'Salut',
    'Latest projects': 'Projets récents',
    'Finished': 'Complétés',
    'In production': 'En cours de développement',
    'Pending Revenue': 'Recettes en attente',
    'Projects Revenue': 'Recettes du projet',
    'Project Breakdown': 'Aperçu',
    'Project details': 'Détails du projet',
    'Your revenue for this project': 'Vos recettes pour ce projet',
    'Project status': 'Statut du projet',
    'Total project revenue': 'Recettes totales pour ce projet',
    'Projects address': 'Addresse du projet',
    'Pending revenue': 'Recettes en attente',
    'Initial funding': 'Financement de départ',
    'Payed out': 'Déja payées',
    'Quota': 'Quota',
    'Set Picture Lock': "Fixer date d'échéance",
    'Project members': 'Membres du projet',
    'Add Member': 'Ajouter un membre',
    'Username': "Nom d'utilisateur",
    'Address': 'Addresse',
    'Percentage': 'Pourcentage',
    'Update Quota': 'Modifier le quota',
    'Title': 'Titre',
    'Status': 'Statut',
    'Payout': 'Payer recettes',
    'Add Reveneue': 'Ajouter des recettes',
    'Revenue': 'Recettes',
    'Cancel': 'Annuler',
    'First Name': 'Prénom',
    'Enter First Name': 'Ajouter prénom',
    'Last Name': 'Nom de famille',
    'Enter Last Name': 'Ajouter nom de famille',
    'Street, Address': 'Rue, addresse',
    'Enter Street': 'Ajouter la rue',
    'Additional Address': "Complément d'addresse",
    'Additional address eg Apartment and floor': "Complément d'addresse (appartement, étage)",
    'City': 'Ville',
    'Enter City': 'Ajouter ville',
    'Postcode': 'Code postal',
    'Postal Code': 'Code postal',
    'Phone No': 'Numéro de téléphone',
    'Enter Phone No': 'Ajouter numéro de téléphone',
    'Phone Number must be at least 10 characters': 'Le numéro de téléphone doit contenir au moins 10 caractères',
    'Account Name': 'Nom du titulaire du compte',
    'Enter Account Name': 'Ajouter le titulaire du compte',
    'IBAN': 'IBAN',
    'Enter IBAN': 'Ajouter IBAN',
    'IBAN must be at least 15 characters': 'Votre code IBAN doit contenir au moins 15 caractères',
    'Search projects': 'Rechercher des projets',
    'Request To Become Owner': 'Envoyer une demande pour devenir propriétaire',
    'No finished projects': 'Aucun projet complété',
    'Filter list': 'Filtrer',
    'Delete': 'Supprimer',
    'Copy to Clipboard': 'Copier vers le presse-papiers',
    'Copied': 'Copié',
    'Enter revenue (€)': 'Ajouter une recette (€)',
    'Loading': 'Chargement en cours',
    'Required': 'Champs obligatoires',
    'Enter quota': 'Ajouter un quota',
    'Search users': 'Rechercher des utilisateurs',
    'Enter User Address': "Ajouter l'addresse de l'utilisateur",
    'Basis': 'Point de base',
    'Funding Commision/Invest': 'Organisme de financement',
    'Amount': 'Montant',
    'Add members': 'Ajouter des membres',
    'CSF name': 'Nom du CSF',
    'CSF %': '% du CSF',
    'Project Name required': 'Nom du projet obligatoire',
    'Project Name': 'Nom du projet',
    'Enter Project name': 'Ajouter le nom du projet',
    'Add': 'Ajouter',
    'File size should not be larger than 3MB': 'La taille des fichiers ne peut exceder 3MB',
    'Allowed file types are: .jpg, .jpeg, .png and .tiff': 'Formats autorisés : .jpg, .jpeg, .png et .tiff',
    'Upload Poster': 'Télécharger le poster',
    'Your info': 'Vos informations personnelles',
    'Save': 'Sauvegarder',
    'Login': 'Se connecter',
    'Please provide valid Email': "Merci d'ajouter une addresse e-mail valide",
    'Email': 'Email',
    'Enter Email': 'Ajouter addresse e-mail',
    'Password': 'Mot de passe',
    'Enter Password': 'Ajouter mot de passe',
    'Forgot password?': 'Mot de passe oublié ?',
    'Sign In': 'Se connecter',
    'Do you want to register? Sign Up': "S'inscrire",
    'New password': 'Nouveau mot de passe',
    'Passwords do not match': 'Les mots de passe saisis ne sont pas identiques',
    'Confirm Password': 'Confirmer mot de passe',
    'Already have an account? Sign in': 'Déja inscrits ? Connectez-vous',
    'Reset password': 'Modifier le mot de passe',
    'Password not strong enough': "Votre mot de passe n'est pas assez robuste",
    'Sign Up': "S'inscrire",
    'The verification link has been sent to your email.': 'Le lien de vérification vous a été envoyé par e-mail',
    "Your account has been created. If you don't see the email please check your spam folder or click the button bellow to resend it.": "Votre compte a été créé. Si vous ne recevez pas l'email de confirmation, merci de vérifier dans vos spams, ou cliquez sur le lien ci-dessous pour le renvoyer.",
    'Resend email': "Renvoyer l'e-mail",
    'Sign in with a different account': 'Se connecter avec un autre compte',
    'The confirmation link has been expired. Use resend Confirmation to get new verification link': 'Le lien de confirmation a expiré. Cliquez sur "Renvoyer la confirmation" pour obtenir un nouveau lien',
    'Resend Confirmation': 'Renvoyer la confirmation',
    'Account verification is in process': 'Vérification du compte en cours',
    'Forgot password': 'Mot de passe oublié',
    'Confirmation Link has Expired, Use resend Confirmation to get new link': 'Le lien de confirmation a expiré. Cliquez sur "Renvoyer la confirmation" pour obtenir un nouveau lien',
    'Your account has been verified': 'Votre compte a été vérifié avec succès',
    'Data saved.': 'Données enregistrées',
    'Request has been sent to the admin.': 'Votre demande a été envoyée à un administrateur',
    'Project created.': 'Projet créé',
    'Revenue added.': 'Recette ajoutée',
    'Picture Lock set.': "Date d'échéance fixée",
    'Success': 'Transaction complétée avec succès',
    'Quota updated.': 'Quota mis a jour',
    'Confirmation Link has been sent. Dont forget to check spam folder': "Le lien de confirmation vous a été envoyé par e-mail. N'oubliez pas de vérifier dans vos spams",
    'Reset Link has been sent. Dont forget to check spam folder': "Le lien de modification vous a été envoyé par e-mail. N'oubliez pas de vérifier dans vos spams",
    'Password has been reset.': 'Votre mot de passe a été modifié',
    'Add Revenue': 'Ajouter recette',
    'Portuguese': 'Portugais',
    'French': 'Français',
    'Spanish': 'Espagnol',
    'German': 'Allemand',
    'English': 'Anglais',
    'Rows per page:': 'Nombre de lignes par page:',
    'Please use a valid e-mail address.': 'Please use a valid e-mail address.',
    'Play sound on Sign In': 'Play sound on Sign In',
    'No projects': 'No projects',
    'For a better experience use our mobile app.': 'For a better experience use our mobile app.',
}
