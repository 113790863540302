import React, { useEffect, useState } from 'react';
import {
    Stack,
    Container,
    Typography,
    CircularProgress,
} from '@mui/material';
import Page from '../../components/Page/Page';
import useAuth from '../../utils/hooks/useAuth';
import { UserForm } from './components/UserForm';
import { translate } from '../../utils/localization/translate';

export default function UserView() {
    const { getLoggedInUser } = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const { t } = translate();

    const [user, setUser] = useState(null);

    useEffect(() => {
        getUser();
    }, []);

    const getUser = async () => {
        const user = await getLoggedInUser();
        setUser(user);
        setIsLoading(false);
    };

    if (isLoading) {
        return <CircularProgress />;
    }

    return (
        <Page>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        {t('Your info')}
                    </Typography>
                </Stack>
                <UserForm user={user} />
            </Container>
        </Page>
    );
}
