import React, { createContext, useContext, useReducer } from 'react';

export default function makeContextStore( reducer, initialState ) {
  const StoreContext = createContext({});
  const DispatchContext = createContext({});

  const StoreProvider = ({ children }) => {
    const [store, dispatch] = useReducer(reducer, initialState);

    return (
      <DispatchContext.Provider value={dispatch}>
        <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
      </DispatchContext.Provider>
    );
  };

  function useStore() {
    return useContext(StoreContext);
  }

  function useDispatch() {
    return useContext(DispatchContext);
  }

  return [StoreProvider, useStore, useDispatch];
}
