export const de = {
  Home: 'Home',
  Projects: 'Projekte',
  'Create Project': 'Projekt Kreiieren',
  Search: 'Suche',
  Profile: 'Profil',
  Settings: 'Einstellungen',
  Logout: 'Logout',
  Hi: 'Hallo',
  'Latest projects': 'Letzten Projekte',
  Finished: 'Abgeschlossen',
  'In production': 'In Produktion',
  'Pending Revenue': 'Ausstehende Erlöse',
  'Projects Revenue': 'Projekterlöse',
  'Project Breakdown': 'Projektzusammensetzung',
  'Project details': 'Projektdetails',
  'Your revenue for this project': 'Deine Projekterlöse',
  'Project status': 'Projektstatus',
  'Total project revenue': 'Gesamterlös Projekt',
  'Project address': 'Projektadresse',
  'Pending revenue': 'Ausstehende Erlöse',
  'Initial funding': 'Ursprüngliche Fördersumme',
  'Payed out': 'Ausgeschüttet',
  Quota: 'Quote',
  'Set Picture Lock': 'Picture Lock Aktivieren',
  'Project members': 'Teammitglieder Projekt',
  'Add Member': 'Teammitglied Hinzufügen',
  Username: 'User-Name',
  Address: 'Adresse',
  Percentage: 'Prozent(e)/BPS',
  'Update Quota': 'Quote Aktualisieren',
  Title: 'Titel',
  Status: 'Status',
  Payout: 'Auszahlung',
  'Add Reveneue': 'Erlöse hinzufügen',
  'Revenue Type': 'Einnahmeart',
  Revenue: 'Erlöse',
  Cancel: 'Löschen',
  'First Name': 'Vorname',
  'Enter First Name': 'Vorname eintragen',
  'Last Name': 'Nachname',
  'Enter Last Name': 'Nachname eintragen',
  'Street, Address': 'Straße, Adresse',
  'Enter Street': 'Straße eintragen',
  'Additional Address': 'Adressenzusätze',
  'Additional address eg Apartment and floor': 'Adressenzusätze z.B. Apartmentnummer, Stockwerk, etc.',
  City: 'Ort',
  'Enter City': 'Ort eintragen',
  Postcode: 'Postcode',
  'Postal Code': 'Postleitzahl',
  'Phone No': 'Telefonnummer',
  'Enter Phone No': 'Telefonnummer eintragen',
  'Phone Number must be at least 10 characters': 'Telefonnummer muss mindestens 10 Ziffern/Zahlen haben',
  'Account Name': 'Kontoinhaber',
  'Enter Account Name': 'Kontoinhaber eintragen',
  IBAN: 'IBAN',
  'Enter IBAN': 'IBAN eintragen',
  'IBAN must be at least 15 characters': 'IBAN hat Minimum 15 Zeichen',
  'Search projects': 'Projektsuche',
  'Request To Become Owner': 'Projekteigentümer werden',
  'No finished projects': 'Keine abgeschlossenen Projekte',
  'Filter list': 'Filter-Liste',
  Delete: 'Löschen',
  'Copy to Clipboard': 'Kopieren und merken',
  Copied: 'Kopiert',
  'Enter revenue (€)': 'Erlös eintragen (€)',
  Loading: 'Wird geladen',
  Required: 'Benötigt',
  'Enter quota': 'Quote eintragen',
  'Search users': 'Suche Mitglieder',
  'Enter User Address': 'Eintragen Mitgliederadresse',
  Basis: 'Basis',
  'Funding Commision/Invest': 'Förderinstitution/Invest',
  Amount: 'Summe',
  'Add members': 'Mitglied hinzufügen',
  'CSF name': 'CSF Name',
  'CSF %': 'CSF %',
  'Project Name required': 'Projekttitel benötigt',
  'Project Name': 'Projekttitel',
  'Enter Project name': 'Projekttitel eintragen',
  Add: 'Hinzufügen',
  'File size should not be larger than 3MB': 'Datei darf nicht größer sein als 3 MB',
  'Allowed file types are: .jpg, .jpeg, .png and .tiff':
    'Bitte nur folgende Dateitypen nutzen: .jpg, .jpeg, .png und .tiff',
  'Upload Poster': 'Projektplakat hochladen',
  'Your info': 'Deine Info',
  Save: 'Sichern',
  Login: 'Login',
  'Please provide valid Email': 'Bitte eine gültige Email eintragen',
  Email: 'Email',
  'Enter Email': 'Email eintragen',
  Password: 'Passwort',
  'Enter Password': 'Passwort eintragen',
  'Forgot password?': 'Passwort vergessen? ',
  'Sign In': 'Anmelden',
  'Do you want to register? Sign Up': 'Möchtest Du Dich anmelden? Hier anmelden.',
  'New password': 'Neues Passwort',
  'Passwords do not match': 'Passwörter stimmen nicht überein!',
  'Confirm Password': 'Passwort bitte bestätigen',
  'Already have an account? Sign in': 'Hast Du bereits ein Account? Anmelden',
  'Reset password': 'Passwort ersetzen',
  'Password not strong enough': 'Passwort sollte stärker sein',
  'Sign Up': 'Anmelden',
  'The verification link has been sent to your email.': 'Bestätigungslink wurde an Deine Email verschickt!',
  "Your account has been created. If you don't see the email please check your spam folder or click the button bellow to resend it.":
    'Gratuliere! Dein Account wurde erstellt! Falls Du die Mail nicht sogleich findest, schaue bitte in Dein Spamordner oder klicke die Schaltfläche unten an.',
  'Resend email': 'Email bitte erneut schicken',
  'Sign in with a different account': 'Anmelden mit einem anderen Account',
  'The confirmation link has been expired. Use resend Confirmation to get new verification link':
    'Bestätigungslink ist erloschen, bitte Bestätigungsfeld nutzen um einen neuen Link zu bekommen',
  'Resend Confirmation': 'Bestätigung bitte erneut senden.',
  'Account verification is in process': 'Die Verifizierung Deines Accounts ist in Arbeit',
  'Forgot password': 'Passwort vergessen',
  'Confirmation Link has Expired, Use resend Confirmation to get new link':
    'Bestätigungslink ist erloschen, bitte Bestätigungsfeld nutzen um einen neuen Link zu bekommen',
  'Your account has been verified': 'Dein Konto wurde bestätigt',
  'Data saved.': 'Daten gesichert.',
  'Request has been sent to the admin.': 'Deine Anfrage wurde dem Admin geschickt!',
  'Project created.': 'Projekt erstellt!',
  'Revenue added.': 'Erlös hinzugefügt.',
  'Picture Lock set.': '"Picture Lock" aktiviert',
  Success: 'Erfolgreich',
  'Quota updated.': 'Quote(n) aktualisiert',
  'Confirmation Link has been sent. Dont forget to check spam folder':
    'Bestätigungslink wurde verschickt. Bitte nicht vergessen auch im Spamordner nachzuschauen.',
  'Reset Link has been sent. Dont forget to check spam folder':
    'Neuer Link wurde verschickt. Bitte nicht vergessen auch im Spamordner nachzuschauen.',
  'Password has been reset.': 'Passwort wurde geändert',
  'Add Revenue': 'Umsatz hinzufügen',
  Portuguese: 'Portugiesisch',
  French: 'Französisch',
  Spanish: 'Spanisch',
  German: 'Deutsch',
  English: 'Englisch',
  'Rows per page:': 'Reihen pro Seite:',
  'Please use a valid e-mail address.': 'Bitte verwenden Sie eine gültige E-Mail-Adresse.',
  'Play sound on Sign In': 'Ton bei der Anmeldung abspielen',
  'Enter Quota in bps': 'Geben Sie Quota in bps ein'
};
