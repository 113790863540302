import { BASE_URL } from "../../../config";
import ApiService from "../../../services/ApiService";

export class UserService {
  constructor() {
    this.apiService = ApiService.getInstance();
    this.baseApiRoute = '/users';
  }

  async editUser(firstName, lastName, street, additionalAddress, city, postalCode, phoneNo, accountName, iban) {
    const payload = {
      user: {
        firstName,
        lastName,
        address: {
          street,
          additionalAddress,
          city,
          postalCode,
        },
        telephoneNumber: phoneNo,
        accountName,
        accountIban: iban,
      }
    }

    const response = await this.apiService.put(`${BASE_URL}${this.baseApiRoute}/updateSelf`, payload);

    return response;
  }

  async findUser(email) {
    const response = await this.apiService.get(`${BASE_URL}/owner${this.baseApiRoute}/findUsers?email=${email}`);

    return response;
  }

  async changeRole(payload) {
    const response = await this.apiService.put(`${BASE_URL}/auth/sendRoleChangeRequest`, payload);

    return response.data;
  }
}
