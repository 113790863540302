import React from 'react';
import {
    Stack,
    Container,
    Typography,
} from '@mui/material';
import Page from '../../components/Page/Page';
import { translate } from '../../utils/localization/translate';
import { SettingsForm } from './components/SettingsForm';

export default function SettingsView() {
    const { t } = translate();

    return (
        <Page>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        {t('Settings')}
                    </Typography>
                </Stack>
                <SettingsForm />
            </Container>
        </Page>
    );
}
