import useToast from "../../../utils/hooks/useToast";
import { translate } from "../../../utils/localization/translate";
import { VerificationService } from "../services/Verification.Service";

export default function useVerificationService() {
    const verificationService = new VerificationService();
    const toast = useToast();
    const { t } = translate();

    const verifyUser = async (payload) => {
        try {
            const response = await verificationService.verifyUser(payload);
           
            if(response.data.message === "Confirmation Link has expired")
            {
                toast.show({
                    message: t('Confirmation Link has Expired, Use resend Confirmation to get new link'),
                    severity: 'warning',
                    wait: 5000,
                });
                return false;  
            }
            
            if(response.data === "invalid signature")
            {
                toast.show({
                    message: t('Oops. Something went wrong'),
                    severity: 'error',
                    wait: 5000,
                });
                return "invalid signature";  
            }

            toast.show({
                message: t('Your account has been verified'),
                severity: 'success',
                wait: 5000,
            });
            

        } catch (e) {
            toast.show({
                message: e.response.data.message,
                severity: 'error',
                wait: 3000,
            });

            throw e;
        }
    }

    return {
        verifyUser,
    };
}