import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Grid, TextField, Button, InputAdornment } from '@mui/material';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import HolidayVillageOutlinedIcon from '@mui/icons-material/HolidayVillageOutlined';
import MarkunreadMailboxOutlinedIcon from '@mui/icons-material/MarkunreadMailboxOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import PinOutlinedIcon from '@mui/icons-material/PinOutlined';
import Container from '@mui/material/Container';
import styles from "../../Registration/components/Register.module.scss";
import useUserService from '../consumers/useUserService';
import { ContentStyle } from '../../Project/components/CreateProjectForm.styles';
import { translate } from '../../../utils/localization/translate';

export const UserForm = ({ user }) => {
    const { editUser } = useUserService();
    const { t } = translate();

    const initialValues = {
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        street: user.address?.street || '',
        additionalAddress: user.address?.additionalAddress || '',
        city: user.address?.city || '',
        postalCode: user.address?.postalCode || '',
        phoneNo: user.telephoneNumber || '',
        accountName: user.accountName || '',
        iban: user.accountIban || '',
    };

    /**
    * Gets called on Sign Up
    */
    const onSubmit = async (values, props) => {
        await editUser(values.firstName, values.lastName, values.street, values.additionalAddress, values.city, values.postalCode, values.phoneNo, values.accountName, values.iban);

        props.setSubmitting(false);
    };

    /**
     * Form Validation
     */
    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
        phoneNo: Yup.string().matches(/^\+?\d{10,14}$/, t('Invalid Phone Number')).required(t('Required')),
        accountName: Yup.string().required(t('Required')),
        iban: Yup.string().matches(/^[A-Z]{2}\d{2}[A-Z\d]{1,30}$/, t('Invalid IBAN')).required(t('Required')),
    });

    return (
        <ContentStyle className={styles.contentStyle}>
            <Container component="main">
                <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} >
                    {(props) => (
                        <Form className={styles.formContainer}>
                            <div className={styles.flex_col}>
                                <div className={styles.flex}>
                                    <Field as={TextField} className={styles.textField}
                                        fullWidth
                                        name="firstName"
                                        label={t('First Name')}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PersonAddAlt1OutlinedIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        placeholder={t('Enter First Name')}
                                        helperText={<b className={styles.errorText}> <ErrorMessage name="firstName" /> </b>}
                                    />
                                    <Field as={TextField} className={styles.textField}
                                        fullWidth
                                        name="lastName"
                                        label={t('Last Name')}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PersonAddAltOutlinedIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        placeholder={t('Enter Last Name')}
                                        helperText={<b className={styles.errorText}> <ErrorMessage name="lastName" /> </b>}

                                    />
                                </div>
                                <Field as={TextField} className={styles.textField}
                                    fullWidth
                                    name="street"
                                    label={t('Street, Address')}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <HomeOutlinedIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    placeholder={t('Enter Street')}
                                />
                                <Field as={TextField} className={styles.textField}
                                    fullWidth
                                    name="additionalAddress"
                                    label={t('Additional Address')}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <HolidayVillageOutlinedIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    placeholder={t('Additional address eg Apartment and floor')}
                                />
                                <div className={styles.flex}>
                                    <Field as={TextField} className={styles.textField}
                                        fullWidth
                                        name="city"
                                        label={t('City')}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AccountBalanceOutlinedIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        placeholder={t('Enter City')}
                                    />
                                    <Field as={TextField} className={styles.textField}
                                        fullWidth
                                        name="postalCode"
                                        label={t('Postcode')}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <MarkunreadMailboxOutlinedIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        placeholder={t('Postal Code')}
                                    />
                                </div>
                                <Grid item xs={12}>
                                    <Field as={TextField} className={styles.textField}
                                        fullWidth
                                        name="phoneNo"
                                        label={t('Phone No')}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <CallOutlinedIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        placeholder={t('Enter Phone No')}
                                        helperText={<b className={styles.errorText}> <ErrorMessage name="phoneNo" /> </b>}
                                    />
                                </Grid>
                                <div className={styles.flex}>
                                    <Field as={TextField} className={styles.textField}
                                        fullWidth
                                        name="accountName"
                                        label={t('Account Holder Name')}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AccountBalanceOutlinedIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        placeholder={t('Enter Account Name')}
                                        helperText={<b className={styles.errorText}> <ErrorMessage name="accountName" /> </b>}
                                    />
                                    <Field as={TextField} className={styles.textField}
                                        fullWidth
                                        name="iban"
                                        label={t('IBAN')}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PinOutlinedIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        placeholder={t('Enter IBAN')}
                                        helperText={<b className={styles.errorText}> <ErrorMessage name="iban" /> </b>}
                                    />
                                </div>
                            </div>
                            <Button type="submit" variant="contained" className={`${styles.signUpButtonContainer} ${styles.saveButton}`} fullWidth disabled={props.isSubmitting}>
                                {props.isSubmitting ? t('Loading') : t('Save')}
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Container>
        </ContentStyle>
    );
}