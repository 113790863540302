import React from 'react';
import { useRoutes } from 'react-router-dom';
import HomeView from '../../pages/Home/HomeView';
import { CREATE_PROJECT_PAGE, DASHBOARD, HOME_PAGE, PROJECTS_PAGE, PROJECT_DETAILS_PAGE, SETTINGS, USER_PAGE } from '../constants/RouteNames';
import CreateProjectScreen from '../../pages/Project/CreateProjectView';
import MainLayoutComponent from '../../components/MainLayoutComponent/MainLayoutComponent';
import { ProjectDetailsView } from '../../pages/Project/ProjectDetailsView';
import ProjectView from '../../pages/Project/ProjectView';
import UserView from '../../pages/User/UserView';
import SettingsView from '../../pages/Settings/SettingsView';

export default function DashboardRouter() {
  return useRoutes([
    {
      path: DASHBOARD,
      element: <MainLayoutComponent />,
      children: [
        { path: DASHBOARD, element: <HomeView /> },
        { path: `${DASHBOARD}/${HOME_PAGE}`, element: <HomeView /> },
        { path: `${DASHBOARD}/${CREATE_PROJECT_PAGE}`, element: <CreateProjectScreen /> },
        { path: `${DASHBOARD}/${PROJECT_DETAILS_PAGE}`, element: <ProjectDetailsView /> },
        { path: `${DASHBOARD}/${PROJECTS_PAGE}`, element: <ProjectView /> },
        { path: `${DASHBOARD}/${USER_PAGE}`, element: <UserView /> },
        { path: `${DASHBOARD}/${SETTINGS}`, element: <SettingsView /> },
      ],
    },
  ]);
};
