import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import DomainVerificationOutlinedIcon from '@mui/icons-material/DomainVerificationOutlined';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { TextField, Button, InputAdornment, Grid } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import IconButton from '@mui/material/IconButton';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import styles from '../../Login/components/Login.module.scss';
import { LOGIN_PAGE } from '../../../router/constants/RouteNames';
import useAuth from '../../../utils/hooks/useAuth';
import { translate } from '../../../utils/localization/translate';
import PasswordStrengthMeter from '../../../components/PasswordStrengthMeter/PasswordStrengthMeter';

export const NewPasswordForm = () => {
  const { resetPassword } = useAuth();
  const params = useParams();
  const { resetToken } = params;
  const { t } = translate();
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const initialValues = {
    password: '',
    confirmPassword: '',
  };

  /**
   * Gets called on Sign in
   */
  const onSubmit = async (values, props) => {
    const jsonBody = {
      password: values.password,
      resetToken,
    };

    await resetPassword(jsonBody);

    navigate(LOGIN_PAGE, { replace: true });

    props.resetForm();
    props.setSubmitting(false);
  };

  const GetPasswordFromUser = () => {
    // Grab values and submitForm from context

    const { values } = useFormikContext();
    useEffect(() => {
      setPassword(values.password);
    }, [values.password]);

    return null;
  };

  const validationSchema = Yup.object().shape({
    confirmPassword: Yup.string()
      .required(t('Required'))
      .oneOf([Yup.ref('password')], t('Passwords do not match')),
  });

  return (
    <div className={styles.root}>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {(props) => (
          <Form>
            <Grid item xs={12}>
              <Field
                as={TextField}
                className={styles.textField}
                fullWidth
                required
                name="password"
                label={t('Password')}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PasswordOutlinedIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                placeholder={t('Enter Password')}
                helperText={
                  <b className={styles.errorText}>
                    {' '}
                    <ErrorMessage name="password" />{' '}
                  </b>
                }
              />
              <div className="progress">
                <div className="progress-bar" />
                <PasswordStrengthMeter password={password} />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Field
                as={TextField}
                className={styles.textField}
                fullWidth
                required
                name="confirmPassword"
                label={t('Confirm Password')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DomainVerificationOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder={t('Confirm Password')}
                helperText={
                  <b className={styles.errorText}>
                    {' '}
                    <ErrorMessage name="confirmPassword" />{' '}
                  </b>
                }
                type="password"
              />
            </Grid>
            <Link className={styles.link} to={LOGIN_PAGE} variant="body2">
              {t('Already have an account? Sign in')}
            </Link>

            <Button
              type="submit"
              variant="contained"
              className={styles.loginButtonContainer}
              fullWidth
              disabled={props.isSubmitting}
            >
              {props.isSubmitting ? t('Loading') : t('Reset password')}
            </Button>
            <GetPasswordFromUser />
          </Form>
        )}
      </Formik>
    </div>
  );
};
