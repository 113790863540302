import { useAppContextStore } from "../../context/App/App.context"
import { de } from "./dictionaries/de";
import { en } from "./dictionaries/en";
import { es } from "./dictionaries/es";
import { fr } from "./dictionaries/fr";
import { pt } from "./dictionaries/pt";

export const translate = () => {
    const { language } = useAppContextStore();

    const getDictionary = () => {
        switch (language) {
            case 'de':
                return de;
            case 'fr':
                return fr;
            case 'es':
                return es;
            case 'pt':
                return pt;
            case 'en':
            default:
                return en;
        }
    }

    const t = (keyword) => {
        const dictionary = getDictionary();

        let word = keyword;
        Object.entries(dictionary).forEach(([key, value]) => {
            if (key === keyword) {
                word = value;
            }
        });

        return word;
    }

    return {
        t,
    }
}