import { Formik, Form, Field, ErrorMessage } from 'formik';
import React from 'react';
import Decimal from 'decimal.js-light';
import * as Yup from 'yup';
import { TextField, Button, Grid, Typography } from '@mui/material';
import styles from '../../Login/components/Login.module.scss';
import useProjectService from '../consumers/useProjectService';
import { translate } from '../../../utils/localization/translate';

Decimal.set({ precision: 16 });

export const UpdateQuotaForm = ({ projectId, parcId, closeForm, refetchData }) => {
    const { updateQuota } = useProjectService();
    const { t } = translate();

    const initialValues = {
        quota: '',
    };

    /**
     * Form Validation
     */
    const validationSchema = Yup.object().shape({
        quota: Yup.number().required(t('Required')),
    });

    /**
    * Gets called on Sign in
    */
    const handleOnSubmit = async (values, props) => {
        
        const jsonBody = {
            "projectId": projectId,
            "user": {
                "parcId": parcId,
                "quota": parseInt(new Decimal(values.quota).toDecimalPlaces(0), 10)
            }
        }

        await updateQuota(jsonBody);

        closeForm();
        refetchData();

        props.resetForm();
        props.setSubmitting(false);
    };

    return (
        <div className={styles.root}>
            <Formik initialValues={initialValues} onSubmit={handleOnSubmit} validationSchema={validationSchema}>
                {(props) => (
                    <Form>
                        <Grid className={styles.formContainer} container spacing="1" alignItems="center">
                            <Typography variant="contained" align="left" sx={{ color: 'text.primary', mt: 2, mb: -2, fontWeight: 'bold' }}>
                                {t('Update Quota')}
                            </Typography>
                            <Field as={TextField} className={styles.textField}
                                name="quota"
                                label={t('Quota')}
                                placeholder={t('Enter Quota in bps')}
                                fullWidth required
                                helperText={<b className={styles.errorText}> <ErrorMessage name="quota" /> </b>}
                            />

                            <div className={styles.buttonGroup}>
                                <Button variant="contained" className={styles.closeModalButton} fullWidth onClick={() => closeForm()}>
                                    {t('Cancel')}
                                </Button>

                                <Button className={styles.buttonPrimary} type="submit" variant="contained" fullWidth disabled={props.isSubmitting}>
                                    {props.isSubmitting ? t('Loading') : t('Update Quota')}
                                </Button>
                            </div>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </div>
    );
}