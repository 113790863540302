import {
    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    TablePagination,
} from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { filter } from 'lodash';
import SearchNotFound from '../../../components/SearchNotFound/SearchNotFound';
import TableToolbar from '../../../components/TableToolbar/TableToolbar';
import TableHeadComponent from '../../../components/TableHead/TableHead';
import { PROJECT_DETAILS_PAGE } from '../../../router/constants/RouteNames';
import styles from "./ProjectsTable.module.scss";
import Scrollbar from '../../../components/Scrollbar/Scrollbar';
import { translate } from '../../../utils/localization/translate';

export const ProjectsTable = ({ projects }) => {
    const { t } = translate();

    const TABLE_HEAD = [
        { id: 'title', label: t('Title'), alignRight: false },
        { id: 'address', label: t('Address'), alignRight: false },
        { id: 'status', label: t('Status'), alignRight: false },
    ];

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('name');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(20);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = projects.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    function applySortFilter(array, comparator, query) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) { return order; }
            return a[1] - b[1];
        });
        if (query) {
            return filter(array, (_project) => _project.projectName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
        }
        return stabilizedThis.map((el) => el[0]);
    }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const getStatusClass = (status) => {
        switch (status) {
            case 0:
                return styles.inProduction;
            case 1:
            default:
                return styles.finished;
        }
    }

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - projects.length) : 0;

    const filteredProjects = projects ? applySortFilter(projects, getComparator(order, orderBy), filterName) : [];

    const isUserNotFound = filteredProjects.length === 0;

    return (
        <Card className={styles.card}>
            <TableToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

            <Scrollbar>
                <TableContainer>
                    <Table>
                        <TableHeadComponent
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={projects.length}
                            numSelected={selected.length}
                            onRequestSort={handleRequestSort}
                            onSelectAllClick={handleSelectAllClick}
                            withCheckbox={false}
                        />
                        <TableBody>
                            {filteredProjects.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                const { projectId, projectName, projectaddress, projectDetails } = row;
                                const isItemSelected = selected.indexOf(projectName) !== -1;
                                const projectStatus = projectDetails ? (projectDetails.projectStage === 1 ? t('Finished') : t('In production')) : null;

                                return (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={projectId}
                                        role="checkbox"
                                        selected={isItemSelected}
                                        aria-checked={isItemSelected}
                                    >
                                        <TableCell component="th" scope="row" padding="normal">
                                            <Link key={projectId} to={PROJECT_DETAILS_PAGE.replace(':id', projectId)} className={styles.link}>
                                                <Typography variant="subtitle2" noWrap>
                                                    {projectName}
                                                </Typography>
                                            </Link>
                                        </TableCell>
                                        <TableCell align="left">{projectaddress}</TableCell>
                                        <TableCell align="left">
                                            <div className={`${styles.status} ${getStatusClass(projectDetails.projectStage)}`}>
                                                <span>{projectStatus}</span>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>

                        {isUserNotFound && (
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                        <SearchNotFound searchQuery={filterName} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Scrollbar>

            <TablePagination
                rowsPerPageOptions={[20, 50, 100]}
                component="div"
                count={projects.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t('Rows per page:')}
            />
        </Card>
    );
}