import { Box } from '@mui/material';
import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import { LOGIN_PAGE } from '../../../router/constants/RouteNames';
import useVerificationService from '../consumers/useVerificationService';
import styles from './VerificationForm.module.scss';
import useAuth from '../../../utils/hooks/useAuth';
import { translate } from '../../../utils/localization/translate';

export const VerificationForm = ({confirmationCode}) => {
  const { t } = translate();

  useEffect(() => {
    setTimeout(() => {
        confirmEmail();
    }, 1000)
  })

  const { resendConfirmation } = useAuth();
  const [verifyEmailResponse, setVerifyEmailResponse] = useState(true);

  const {verifyUser} = useVerificationService();
  
  const navigate = useNavigate();

  const confirmEmail = async() => {
    const jsonBody = {
        confirmationCode: `${confirmationCode}`,
    }

    const response = await verifyUser(jsonBody);
    if(response === false)
    {
        setVerifyEmailResponse(false);
    }
    else if(response === "invalid signature")
    {
        navigate(LOGIN_PAGE);
    }
    else 
    {
        navigate(LOGIN_PAGE);
    }
  }

  const resendConfirmationLink = async() => {
    const jsonBody = {
        expiredToken: `${confirmationCode}`,
    }

    await resendConfirmation(jsonBody);
  }

  return (
    <>
        <div className={styles.flex_col}>
            <div className={styles.flex}>
                {verifyEmailResponse === false ?
                <>
                    <h3>{t('The confirmation link has been expired. Use resend Confirmation to get new verification link')}</h3> 
                    <LoadingButton className={styles.resendConfirmationButton} onClick={resendConfirmationLink}>{t('Resend Confirmation')}</LoadingButton> 
                </>
                :
                <>
                    <h3>{t('Account verification is in process')}</h3>
                    <Box > <CircularProgress /> </Box>
                </>
                }
            </div>
        </div>
    </>
  )
}
