import axios from 'axios';
import StorageService, { JWT_TOKEN_KEY, REFRESH_TOKEN_KEY } from './StorageService';
import { BASE_URL } from '../config';

let instance = null;

class ApiService {
    constructor() {
        this.storageService = StorageService.getInstance();
        axios.defaults.withCredentials = false;
        axios.defaults.headers['X-Frame-Options'] = 'DENY';
        axios.defaults.headers['Content-Security-Policy'] = "frame-ancestors 'none'";

        this.instance = axios.create({
            baseURL: `${BASE_URL}`,
        });

        this.setRequestInterceptors();
        this.setResponseInterceptors();
    }

    getInstance() {
        if (!instance) {
            instance = new ApiService();
        }
        return instance;
    }

    setRequestInterceptors() {
        console.log("request intercept was called")
        this.instance.interceptors.request.use((request) => {
            return this.appendHeaders(request);
        });
    }

    setResponseInterceptors() {
        this.instance.interceptors.response.use(
            (response) => {
                return response;
            },
            async (error) => {
                const originalRequest = error.config;

                if (error.response.data?.message === 'jwt expired' && !originalRequest._retry) {
                    const response = await this.handleTokenExpiredError(originalRequest);
                    return response;
                }
                
                return Promise.reject(error);
            },
        );
    }

    async handleTokenExpiredError(originalRequest) {
        originalRequest._retry = true;
        await this.sendRefreshToken();
        return this.instance(originalRequest);
    }

    appendHeaders(request) {
        const accessToken = this.storageService.getItem(JWT_TOKEN_KEY);
        const language = this.storageService.getItemFromLocalStorage('language') || 'en';

        if (accessToken) {
            request.headers = {
                ...request.headers,
                Authorization: `Bearer ${accessToken}`,
                'Accept-Language': language,
            };
        } else {
            request.headers = {
                ...request.headers,
                'Accept-Language': language,
            };
        }

        return request;
    }

    async sendRefreshToken() {
        const refreshToken = this.storageService.getItem(REFRESH_TOKEN_KEY);
        const jsonBody = {
            refreshToken
        };

        try {
            const response = await axios.post(`${BASE_URL}/auth/token`, jsonBody);

            this.updateJwtToken(response.data.accessToken);
        } catch(e) {
            window.location.href = '/login';
        }
    }

    updateJwtToken(newToken) {
        this.storageService.clearItem(JWT_TOKEN_KEY);
        this.storageService.setItem(JWT_TOKEN_KEY, newToken);
    }

    async post(route, config = {}, payload = {}, headers = {}) {
        return this.instance.post(route, config, payload, headers);
    }

    // Temporary function until backend syncs up post calls for Login and Registration
    async postNoConfig(route, payload = {}, headers = {}) {
        return this.instance.post(route, payload, headers);
    }

    async put(route, config = {}, headers = {}) {
        return this.instance.put(route, config, headers);
    }

    async get(route, config = {}, headers = {}) {
        return this.instance.get(route, config, headers);;
    }

    async delete(route, config = {}, headers = {}) {
        return this.instance.delete(route, config, headers);
    }
}

export default new ApiService();