import React, { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton } from '@mui/material';
import { blue, teal } from '@mui/material/colors';
import MenuPopover from '../MenuPopover/MenuPopover';
import { USER_KEY } from '../../services/StorageService';
import { useAppDeps } from '../../App';
import useAuth from '../../utils/hooks/useAuth';
import { SETTINGS, USER_PAGE } from '../../router/constants/RouteNames';
import { translate } from '../../utils/localization/translate';

export default function AccountPopover() {
  const anchorRef = useRef(null);

  const { storageService } = useAppDeps();
  const { logout } = useAuth();
  const { t } = translate();

  const jsonUser = storageService.getItem(USER_KEY);
  const user = JSON.parse(jsonUser); 

  const MENU_OPTIONS = [
    {
      label: t('Profile'),
      icon: 'eva:person-fill',
      linkTo: USER_PAGE,
    },
    {
      label: t('Settings'),
      icon: 'eva:settings-2-fill',
      linkTo: SETTINGS,
    },
  ];
  
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const userInitials = `${user.firstName.substring(0, 1)}${user.lastName.substring(0, 1)}`;
  const avatarColor = user.role === "user" ? blue[500]:teal[500];

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          'gap': '4px',
          borderRadius: '4px',
          '&:hover': {
            color: 'black',
            borderRadius: '4px',
            bgcolor: '#efefef',
            opacity: '0.5',
          },
          ...(open && {
            color: 'black',
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '4px',
              position: 'absolute',
              bgcolor: '#efefef',
              opacity: '0.5',
            },
          }),
        }}
      >
        {
          user.imageUrl
          ? <Avatar src={user.imageUrl} alt='profilePic'/>
          : <Avatar sx={{ bgcolor: avatarColor }} >{userInitials}</Avatar>
        }

        <Typography>{`${user.firstName} ${user.lastName}`}</Typography>
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {`${user.firstName} ${user.lastName}`}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user.parcId}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={()=>{ 
                logout();
              }} sx={{ m: 1 }}>
          {t('Logout')}
        </MenuItem>
      </MenuPopover>
    </>
  );
}
