import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { Grid, TextField, Button, InputAdornment, Checkbox } from '@mui/material';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import HolidayVillageOutlinedIcon from '@mui/icons-material/HolidayVillageOutlined';
import MarkunreadMailboxOutlinedIcon from '@mui/icons-material/MarkunreadMailboxOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import PinOutlinedIcon from '@mui/icons-material/PinOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import DomainVerificationOutlinedIcon from '@mui/icons-material/DomainVerificationOutlined';
import Container from '@mui/material/Container';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import IconButton from '@mui/material/IconButton';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import styles from './Register.module.scss';
import useAuth from '../../../utils/hooks/useAuth';
import Logo from '../../../components/Logo/Logo';
import { EMAIL_VERIFICATION_PAGE, LOGIN_PAGE, PRIVACY_POLICY, TERMS_OF_SERVICE } from '../../../router/constants/RouteNames';
import successSound from '../../../assets/audio/KAAATSCHING_SOUND_1st.mov';
import { translate } from '../../../utils/localization/translate';
import PasswordStrengthMeter from '../../../components/PasswordStrengthMeter/PasswordStrengthMeter';

export const RegistrationForm = () => {
  const { signup } = useAuth();
  const navigate = useNavigate();
  const { t } = translate();
  const [audio] = useState(new Audio(successSound));
  const [playing, setPlaying] = useState(false);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [hasConsentToToS, setTosConsent] = useState(false);
  const [hasConsentToPrivacyPolicy, setPrivacyPolicyConsent] = useState(false);

  const toggleAudio = () => setPlaying(!playing);

  useEffect(() => {
    if (playing) {
      audio.volume = 0.2;
      audio.play();
      return;
    }

    audio.pause();
  }, [playing]);

  const initialValues = {
    firstName: '',
    lastName: '',
    street: '',
    additionalAddress: '',
    city: '',
    postalCode: '',
    phoneNo: '',
    accountName: '',
    iban: '',
    email: '',
    password: '',
    confirmPassword: '',
    hasConsentToToS: false,
    hasConsentToPrivacyPolicy: false

  };

  const GetPasswordFromUser = () => {
    // Grab values and submitForm from context

    const { values } = useFormikContext();
    useEffect(() => {
      setPassword(values.password);
    }, [values.password]);

    return null;
  };

  const handleTosConsentChange = (event) => {
    setTosConsent(event.target.checked);
  };

  const handlePrivacyPolicyConsentChange = (event) => {
    setPrivacyPolicyConsent(event.target.checked);
  };

  /**
   * Gets called on Sign Up
   */
  const onSubmit = async (values, props) => {
    try {
      await signup(
        values.firstName,
        values.lastName,
        values.email,
        values.password,
        values.street,
        values.additionalAddress,
        values.city,
        values.postalCode,
        values.phoneNo,
        values.accountName,
        values.iban,
        hasConsentToToS,
        hasConsentToPrivacyPolicy
      );

      toggleAudio();
      setTimeout(() => {
        toggleAudio();

        props.setSubmitting(false);
        props.resetForm();

        navigate(EMAIL_VERIFICATION_PAGE.replace(':email', values.email));
      }, 1000);
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Form Validation
   */
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string().email(t('Please provide valid Email')).required(t('Required')),
    phoneNo: Yup.string()
      .matches(/^\+?\d{10,14}$/, t('Invalid Phone Number'))
      .required(t('Required')),
    accountName: Yup.string().required(t('Required')),
    iban: Yup.string()
      .matches(/^[A-Z]{2}\d{2}[A-Z\d]{1,30}$/, t('Invalid IBAN, IBAN should have minimum 15 digits and no spaces'))
      .required(t('Required')),
    password: Yup.string().required('Required'),
    confirmPassword: Yup.string()
      .required(t('Required'))
      .oneOf([Yup.ref('password')], t('Passwords do not match')),
    hasConsentToToS: Yup.boolean().required('Required').default(false),
    hasConsentToPrivacyPolicy: Yup.boolean().required('Required').default(false),
  });

  return (
    <Container component="main" maxWidth="sm">
      <Grid className={styles.grid}>
        <Logo disabledLink={true} />
      </Grid>
      <div>
        <p className={styles.heading}>{t('Sign Up')}</p>
      </div>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {(props) => (
          <Form>
            <div className={styles.flex_col}>
              <div className={styles.flex}>
                <Field
                  as={TextField}
                  className={styles.textField}
                  fullWidth
                  name="firstName"
                  label={t('First Name')}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonAddAlt1OutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t('Enter First Name')}
                  helperText={
                    <b className={styles.errorText}>
                      {' '}
                      <ErrorMessage name="firstName" />{' '}
                    </b>
                  }
                />
                <Field
                  as={TextField}
                  className={styles.textField}
                  fullWidth
                  name="lastName"
                  label={t('Last Name')}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonAddAltOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t('Enter Last Name')}
                  helperText={
                    <b className={styles.errorText}>
                      {' '}
                      <ErrorMessage name="lastName" />{' '}
                    </b>
                  }
                />
              </div>
              <Field
                as={TextField}
                className={styles.textField}
                fullWidth
                name="street"
                label={t('Street, Address')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <HomeOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder={t('Enter Street')}
              />
              <Field
                as={TextField}
                className={styles.textField}
                fullWidth
                name="additionalAddress"
                label={t('Additional Address')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <HolidayVillageOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder={t('Additional address eg Apartment and floor')}
              />
              <div className={styles.flex}>
                <Field
                  as={TextField}
                  className={styles.textField}
                  fullWidth
                  name="city"
                  label={t('City')}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountBalanceOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t('Enter City')}
                />
                <Field
                  as={TextField}
                  className={styles.textField}
                  fullWidth
                  name="postalCode"
                  label={t('Postcode')}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MarkunreadMailboxOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t('Postal Code')}
                />
              </div>
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  className={styles.textField}
                  fullWidth
                  name="phoneNo"
                  label={t('Phone No')}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CallOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t('Enter Phone No')}
                  helperText={
                    <b className={styles.errorText}>
                      {' '}
                      <ErrorMessage name="phoneNo" />{' '}
                    </b>
                  }
                />
              </Grid>
              <div className={styles.flex}>
                <Field
                  as={TextField}
                  className={styles.textField}
                  fullWidth
                  name="accountName"
                  label={t('Account Holder Name')}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountBalanceOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t('Enter Account Name')}
                  helperText={
                    <b className={styles.errorText}>
                      {' '}
                      <ErrorMessage name="accountName" />{' '}
                    </b>
                  }
                />
                <Field
                  as={TextField}
                  className={styles.textField}
                  fullWidth
                  name="iban"
                  label={t('IBAN')}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PinOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t('Enter IBAN')}
                  helperText={
                    <b className={styles.errorText}>
                      {' '}
                      <ErrorMessage name="iban" />{' '}
                    </b>
                  }
                />
              </div>
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  className={styles.textField}
                  fullWidth
                  required
                  name="email"
                  label={t('Email')}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AlternateEmailOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t('Enter Email')}
                  helperText={
                    <b className={styles.errorText}>
                      {' '}
                      <ErrorMessage name="email" />{' '}
                    </b>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  className={styles.textField}
                  fullWidth
                  required
                  name="password"
                  label={t('Password')}
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PasswordOutlinedIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)}>
                          {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t('Enter Password')}
                  helperText={
                    <b className={styles.errorText}>
                      {' '}
                      <ErrorMessage name="password" />{' '}
                    </b>
                  }
                />
                <div className="progress">
                  <div className="progress-bar" />
                  <PasswordStrengthMeter password={password} />
                </div>
              </Grid>
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  className={styles.textField}
                  fullWidth
                  required
                  name="confirmPassword"
                  label={t('Confirm Password')}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DomainVerificationOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t('Confirm Password')}
                  helperText={
                    <b className={styles.errorText}>
                      {' '}
                      <ErrorMessage name="confirmPassword" />{' '}
                    </b>
                  }
                  type="password"
                />
              </Grid>
              <Grid container justifyContent="flex-right">
                <Grid item>
                <Checkbox
                  onChange={handlePrivacyPolicyConsentChange}
                  required
                  size='small'
                  name="hasConsentToPrivacyPolicy"
                />
                I have read and agreed to the{' '}
                <Link className={styles.link} to={PRIVACY_POLICY} variant="body2">
                    {t('Privacy Policy')}
                    </Link>
                </Grid>
                <Grid item>
                <Checkbox
                  required
                  onChange={handleTosConsentChange}
                  size='small'
                  name="hasConsentToToS"
                />
                I have read and agreed to the{' '}
                <Link className={styles.link} to={TERMS_OF_SERVICE} variant="body2">
                    {t('Terms of Service')}
                    </Link>
                </Grid>                         
              </Grid>
            </div>
            <Button
              type="submit"
              variant="contained"
              className={styles.signUpButtonContainer}
              fullWidth
              disabled={props.isSubmitting}
            >
              {props.isSubmitting ? t('Loading') : t('Sign Up')}
            </Button>
            <GetPasswordFromUser />
          </Form>
        )}
      </Formik>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Link className={styles.link} to={LOGIN_PAGE} variant="body2">
            {t('Already have an account? Sign in')}
          </Link>
        </Grid>
      </Grid>
    </Container>
  );
};
