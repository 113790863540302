export const en = {
    'Home': 'Home',
    'Projects': 'Projects',
    'Create Project': 'Create Project',
    'Search': 'Search',
    'Profile': 'Profile',
    'Settings': 'Settings',
    'Logout': 'Logout',
    'Hi': 'Hi',
    'Latest projects': 'Latest projects',
    'Finished': 'Finished',
    'In production': 'In production',
    'Pending Revenue': 'Pending Revenue',
    'Projects Revenue': 'Projects Revenue',
    'Project Breakdown': 'Project Breakdown',
    'Project details': 'Project details',
    'Your revenue for this project': 'Your revenue for this project',
    'Project status': 'Project status',
    'Total project revenue': 'Total project revenue',
    'Projects address': 'Projects address',
    'Pending revenue': 'Pending revenue',
    'Initial funding': 'Initial funding',
    'Payed out': 'Payed out',
    'Quota': 'Quota',
    'Set Picture Lock': 'Set Picture Lock',
    'Project members': 'Project members',
    'Add Member': 'Add Member',
    'Username': 'Username',
    'Address': 'Address',
    'Percentage': 'Percentage',
    'Update Quota': 'Update Quota',
    'Title': 'Title',
    'Status': 'Status',
    'Payout': 'Payout',
    'Add Reveneue': 'Add Reveneue',
    'Revenue': 'Revenue',
    'Cancel': 'Cancel',
    'First Name': 'First Name',
    'Enter First Name': 'Enter First Name',
    'Last Name': 'Last Name',
    'Enter Last Name': 'Enter Last Name',
    'Street, Address': 'Street, Address',
    'Enter Street': 'Enter Street',
    'Additional Address': 'Additional Address',
    'Additional address eg Apartment and floor': 'Additional address eg Apartment and floor',
    'City': 'City',
    'Enter City': 'Enter City',
    'Postcode': 'Postcode',
    'Postal Code': 'Postal Code',
    'Phone No': 'Phone No',
    'Enter Phone No': 'Enter Phone No',
    'Phone Number must be at least 10 characters': 'Phone Number must be at least 10 characters',
    'Account Name': 'Account Name',
    'Enter Account Name': 'Enter Account Name',
    'IBAN': 'IBAN',
    'Enter IBAN': 'Enter IBAN',
    'IBAN must be at least 15 characters': 'IBAN must be at least 15 characters',
    'Search projects': 'Search projects',
    'Request To Become Owner': 'Request To Become Owner',
    'No finished projects': 'No finished projects',
    'Filter list': 'Filter list',
    'Delete': 'Delete',
    'Copy to Clipboard': 'Copy to Clipboard',
    'Copied': 'Copied',
    'Enter revenue (€)': 'Enter revenue (€)',
    'Loading': 'Loading',
    'Required': 'Required',
    'Enter quota': 'Enter quota',
    'Search users': 'Search users',
    'Enter User Address': 'Enter User Address',
    'Basis': 'Basis',
    'Funding Commision/Invest': 'Funding Commision/Invest',
    'Amount': 'Amount',
    'Add members': 'Add members',
    'CSF name': 'CSF name',
    'CSF %': 'CSF %',
    'Project Name required': 'Project Name required',
    'Project Name': 'Project Name',
    'Enter Project name': 'Enter Project name',
    'Add': 'Add',
    'File size should not be larger than 3MB': 'File size should not be larger than 3MB',
    'Allowed file types are: .jpg, .jpeg, .png and .tiff': 'Allowed file types are: .jpg, .jpeg, .png and .tiff',
    'Upload Poster': 'Upload Poster',
    'Your info': 'Your info',
    'Save': 'Save',
    'Login': 'Login',
    'Please provide valid Email': 'Please provide valid Email',
    'Email': 'Email',
    'Enter Email': 'Enter Email',
    'Password': 'Password',
    'Enter Password': 'Enter Password',
    'Forgot password?': 'Forgot password?',
    'Sign In': 'Sign In',
    'Do you want to register? Sign Up': 'Do you want to register? Sign Up',
    'New password': 'New password',
    'Passwords do not match': 'Passwords do not match',
    'Confirm Password': 'Confirm Password',
    'Already have an account? Sign in': 'Already have an account? Sign in',
    'Reset password': 'Reset password',
    'Password not strong enough': 'Password not strong enough',
    'Sign Up': 'Sign Up',
    'The verification link has been sent to your email.': 'The verification link has been sent to your email.',
    "Your account has been created. If you don't see the email please check your spam folder or click the button bellow to resend it.": "Your account has been created. If you don't see the email please check your spam folder or click the button bellow to resend it.",
    'Resend email': 'Resend email',
    'Sign in with a different account': 'Sign in with a different account',
    'The confirmation link has been expired. Use resend Confirmation to get new verification link': 'The confirmation link has been expired. Use resend Confirmation to get new verification link',
    'Resend Confirmation': 'Resend Confirmation',
    'Account verification is in process': 'Account verification is in process',
    'Forgot password': 'Forgot password',
    'Confirmation Link has Expired, Use resend Confirmation to get new link': 'Confirmation Link has Expired, Use resend Confirmation to get new link',
    'Your account has been verified': 'Your account has been verified',
    'Data saved.': 'Data saved.',
    'Request has been sent to the admin.': 'Request has been sent to the admin.',
    'Project created.': 'Project created.',
    'Revenue added.': 'Revenue added.',
    'Picture Lock set.': 'Picture Lock set.',
    'Success': 'Success',
    'Quota updated.': 'Quota updated.',
    'Confirmation Link has been sent. Dont forget to check spam folder': 'Confirmation Link has been sent. Dont forget to check spam folder',
    'Reset Link has been sent. Dont forget to check spam folder': 'Reset Link has been sent. Dont forget to check spam folder',
    'Password has been reset.': 'Password has been reset.',
    'Add Revenue': 'Add Revenue',
    'Portuguese': 'Portuguese',
    'French': 'French',
    'Spanish': 'Spanish',
    'German': 'German',
    'English': 'English',
    'Rows per page:': 'Rows per page:',
    'Please use a valid e-mail address.': 'Please use a valid e-mail address.',
    'Play sound on Sign In': 'Play sound on Sign In',
    'No projects': 'No projects',
    'For a better experience use our mobile app.': 'For a better experience use our mobile app.',
    'Enter Quota in bps': 'Enter Quota in bps'
}
