import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {ReactComponent as AppLogo} from '../../assets/images/Logo.svg';
import { HOME_PAGE } from '../../router/constants/RouteNames';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
};

export default function Logo({ disabledLink = false, className = '' }) {
  if (disabledLink) {
    return <AppLogo className={className} />;
  }

  return <RouterLink to={HOME_PAGE} style={{width: '100%'}} className={className}><AppLogo /></RouterLink>;
}
