export const AppActionType = {
  SET_PROJECT_DATA: 'SET_PROJECT_DATA',
  SET_LANGUAGE: 'SET_LANGUAGE',
  SET_BREAKDOWN_CHART: 'SET_BREAKDOWN_CHART', 
}

export const SetProjectDataState = {
  type: AppActionType.SET_PROJECT_DATA,
  payload: [],
}

export const SetLanguageState = {
  types: AppActionType.SET_LANGUAGE,
  payload: '',
}

export const SetBreakdownChartState = {
  types: AppActionType.SET_BREAKDOWN_CHART,
  payload: [],
}

export const AppActions = SetProjectDataState || SetLanguageState;
