import {
    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    TablePagination,
    CircularProgress,
    Button,
} from '@mui/material';
import { useState } from 'react';
import { filter } from 'lodash';
import SearchNotFound from '../../../components/SearchNotFound/SearchNotFound';
import TableToolbar from '../../../components/TableToolbar/TableToolbar';
import TableHeadComponent from '../../../components/TableHead/TableHead';
import styles from "./ProjectsTable.module.scss";
import { UpdateQuotaForm } from './UpdateQuotaForm';
import Scrollbar from '../../../components/Scrollbar/Scrollbar';
import { translate } from '../../../utils/localization/translate';

export const OwnerUserTable = ({ projectId, userList, refetchData, isProjectFinished }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedParcId, setSelectedParcId] = useState(false);
    const { t } = translate();

    const TABLE_HEAD = [
        { id: 'Account Holder', label: t('Account Holder'), alignRight: false },
        { id: 'IBAN', label: t('IBAN'), alignRight: false },
        { id: 'pendingRevenue', label: t('Pending revenue'), alignRight: false },
        { id: 'percentage', label: t('bps'), alignRight: false },
        { id: '' },
    ];

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('name');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(20);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = userList.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    function applySortFilter(array, comparator, query) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) { return order; }
            return a[1] - b[1];
        });
        if (query) {
            return filter(array, (_user) => _user.accountName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
        }
        return stabilizedThis.map((el) => el[0]);
    }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const handleOpenModal = (parcId) => {
        setSelectedParcId(parcId);
        setIsModalOpen(true);
    }

    const handleBackgroundClick = (e) => {
        const modal = document.getElementById('modal');
        if (e.target === modal) {
            setIsModalOpen(false);
        }
    }

    if (!userList) {
        return <CircularProgress />;
    }

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;

    const filteredUsers = applySortFilter(userList, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredUsers.length === 0;

    return (
        <>
            <Card className={`${styles.card} ${styles.tableCard}`}>
                <TableToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

                <Scrollbar sx={{ height: { xs: 140, sm: 'auto' } }}>
                    <TableContainer sx={{ minWidth: 800 }}>
                        <Table>
                            <TableHeadComponent
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={userList.length}
                                numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                                onSelectAllClick={handleSelectAllClick}
                                withCheckbox={false}
                            />
                            <TableBody>
                                {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                    const { accountIban, parcId, accountName, userTO } = row;

                                    const isItemSelected = selected.indexOf(accountName) !== -1;

                                    return (
                                        <TableRow
                                            hover
                                            key={parcId}
                                            tabIndex={-1}
                                            role="checkbox"
                                            selected={isItemSelected}
                                            aria-checked={isItemSelected}
                                        >
                                            <TableCell component="th" scope="row" padding="normal">
                                                <Typography variant="subtitle2" noWrap>
                                                    {accountName}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="left">{accountIban}</TableCell>
                                            <TableCell align="left">{parseFloat(userTO.pendingRevenue).toFixed(2).concat(" € ")}</TableCell>
                                            <TableCell align="left">{parseFloat(userTO.quota).toFixed(0)}</TableCell>

                                            <TableCell align="right">
                                                <Button className={styles.buttonPrimary} variant="contained" onClick={() => handleOpenModal(parcId)} disabled={isProjectFinished}>
                                                    {t('Update Quota')}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>

                            {isUserNotFound && (
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                            <SearchNotFound searchQuery={filterName} />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Scrollbar>

                <TablePagination
                    rowsPerPageOptions={[20, 50, 100]}
                    component="div"
                    count={userList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t('Rows per page:')}
                />
            </Card>
            {isModalOpen &&
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                <div className={styles.modal} id='modal' onClick={(e) => handleBackgroundClick(e)}>
                    <div className={styles.modalContainer}>
                        <UpdateQuotaForm projectId={projectId} parcId={selectedParcId} closeForm={() => setIsModalOpen(false)} refetchData={refetchData} />
                    </div>
                </div>
            }</>
    );
}