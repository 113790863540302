import React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import useToast from '../../utils/hooks/useToast';
import styles from './FileItem.module.scss';
import { translate } from '../../utils/localization/translate';


const FileUpload = ({ file, setFile }) => {
    const allowedFileTypes = ["image/jpeg", "image/png", "image/tiff", "image/jpg"];
    const toast = useToast();
    const { t } = translate();

    const posterUploadHandler = (event) => {
        file = event.target.files[0];
        file.isUploading = true;
        if (file.size > 3145728) {
            toast.show({
                message: t('File size should not be larger than 3MB'),
                severity: 'error',
                wait: 3000,
            });

            return;
        }

        if (!allowedFileTypes.includes(file.type)) {
            toast.show({
                message: t('Allowed file types are: .jpg, .jpeg, .png and .tiff'),
                severity: 'error',
                wait: 3000,
            });

            return;
        }
        
        setFile(file);
        file.isUploading = false;
    }

    return (
        <Stack direction="row" alignItems="center" spacing={2}>
            <Button className={styles.uploadButton} onClick={(e) => { e.target.value = null; }} onChange={posterUploadHandler} variant="contained" component="label">
                {t('Upload Poster')}
                <input hidden accept=".jpg, .jpeg, .png, .tiff" type="file" />
            </Button>
        </Stack>
    )
}

export default FileUpload;