import { Link } from "react-router-dom";
import { PosterView } from "../../../components/PosterView/PosterVIew";
import { PROJECT_DETAILS_PAGE } from "../../../router/constants/RouteNames";
import styles from './ProjectListComponent.module.scss';

export const ProjectListComponent = ({ userProjects }) => {
    return (
        <div className={styles.grid}>
            {userProjects.map((item, index) => {
                return (
                    <Link key={index} to={PROJECT_DETAILS_PAGE.replace(':id', item.projectId)} className={styles.link}>
                        <PosterView project={item} />
                    </Link>
                );
            })}
        </div>
    );
}