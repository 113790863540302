import { BASE_URL } from "../../../config";
import ApiService from "../../../services/ApiService";

export class VerificationService {
  constructor() {
    this.apiService = ApiService.getInstance();
    this.baseApiRoute = '/users/verifyUser';
  }

  verifyUser(payload) {
    return this.apiService.post(`${BASE_URL}${this.baseApiRoute}`, payload);
  }
}
