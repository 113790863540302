import React from 'react';
import { MenuItem, FormControl, Select } from '@mui/material';
import styles from './ProjectPopover.module.scss';
import { translate } from '../../../utils/localization/translate';

export default function ProjectPopover({ projects, onClick, activeProject }) {
    const { t } = translate();
    const handleChange = (event) => {
        onClick(event.target.value);
    };

    return (
        <FormControl
            style={{ boxShadow: '0px 3px 1px -2px rgb(145 158 171 / 20%), 0px 2px 2px 0px rgb(145 158 171 / 14%), 0px 1px 5px 0px rgb(145 158 171 / 12%)', borderRadius: '8px', background: 'white' }}
            className={styles.select}
        >
            <Select
                value={activeProject.id}
                onChange={handleChange}
            >
                {projects.map((project) => (
                    <MenuItem key={project.address} value={project.address} classes={{ selected: styles.itemSelected }}>
                        {t(project.name)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
