import { Formik, Form, Field, ErrorMessage } from 'formik';
import React from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { TextField, Button, InputAdornment } from '@mui/material';
import EmailSharpIcon from '@mui/icons-material/EmailSharp';
import styles from '../../Login/components/Login.module.scss';
import { LOGIN_PAGE } from '../../../router/constants/RouteNames';
import useAuth from '../../../utils/hooks/useAuth';
import { translate } from '../../../utils/localization/translate';

export const ForgotPasswordForm = () => {
    const { sendResetPasswordLink } = useAuth();
    const { t } = translate();

    const initialValues = {
        email: '',
    };

    /**
     * Form Validation
     */
    const validationSchema = Yup.object().shape({
        email: Yup.string().email(t('Please provide valid Email')).required(t('Required')),
    });

    /**
    * Gets called on Sign in
    */
    const onSubmit = async (values, props) => {
        const jsonBody = {
            "email": values.email,
        }

        await sendResetPasswordLink(jsonBody);

        props.resetForm();
        props.setSubmitting(false);
    };

    return (
        <div className={styles.root}>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                {(props) => (
                    <Form>
                        <Field as={TextField} className={styles.textField}
                            name="email"
                            label={t('Email')}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EmailSharpIcon />
                                    </InputAdornment>
                                ),
                            }}
                            placeholder={t('Enter Email')}
                            fullWidth required
                            helperText={<b className={styles.errorText}> <ErrorMessage name="email" /> </b>}
                        />
                        <Link className={styles.link} to={LOGIN_PAGE} variant="body2" >
                            {t('Already have an account? Sign in')}
                        </Link>

                        <Button type="submit" variant="contained" className={styles.loginButtonContainer} fullWidth disabled={props.isSubmitting}>
                            {props.isSubmitting ? t('Loading') : t('Reset password')}
                        </Button>
                    </Form>
                )}
            </Formik>
        </div>
    );
}