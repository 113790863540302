export const es = {
    'Home': 'Inicio',
    'Projects': 'Proyectos',
    'Create Project': 'Crear Proyecto',
    'Search': 'Buscar',
    'Profile': 'Perfil',
    'Settings': 'Configuración',
    'Logout': 'Cerrar sesión',
    'Hi': 'Hola',
    'Latest projects': 'Últimos proyectos',
    'Finished': 'Terminado',
    'In production': 'En producción',
    'Pending Revenue': 'Ingresos pendientes',
    'Projects Revenue': 'Ingresos por proyecto',
    'Project Breakdown': 'Desglose por proyecto',
    'Project details': 'Detalles de proyecto',
    'Your revenue for this project': 'Tus ingresos para este proyecto',
    'Project status': 'Estado del proyecto',
    'Total project revenue': 'Ingresos totales del proyecto',
    'Projects address': 'Dirección del proyecto',
    'Pending revenue': 'Ingresos pendientes',
    'Initial funding': 'Financiación inicial',
    'Payed out': 'Pagados',
    'Quota': 'Cuota',
    'Set Picture Lock': 'Fijar bloqueo de imagen',
    'Project members': 'Miembros del proyecto',
    'Add Member': 'Añadir miembro',
    'Username': 'Nombre de usuario',
    'Address': 'Dirección',
    'Percentage': 'Porcentaje',
    'Update Quota': 'Actualizar cuota',
    'Title': 'Título',
    'Status': 'Estado',
    'Payout': 'Pago',
    'Add Reveneue': 'Añadir ingresos',
    'Revenue': 'Ingresos',
    'Cancel': 'Cancelar',
    'First Name': 'Nombre',
    'Enter First Name': 'Introduzca el nombre',
    'Last Name': 'Apellidos',
    'Enter Last Name': 'Introduzca el apellido',
    'Street, Address': 'Calle, Dirección',
    'Enter Street': 'Introduzca la calle',
    'Additional Address': 'Dirección adicional',
    'Additional address eg Apartment and floor': 'Dirección adicional, por ejemplo, Apartamento y piso',
    'City': 'Ciudad',
    'Enter City': 'Introduzca la ciudad',
    'Postcode': 'Código postal',
    'Postal Code': 'Código postal',
    'Phone No': 'Teléfono',
    'Enter Phone No': 'Introduzca el número de teléfono',
    'Phone Number must be at least 10 characters': 'El número de teléfono debe tener al menos 10 caracteres',
    'Account Name': 'Nombre de la cuenta',
    'Enter Account Name': 'Introduzca el nombre de la cuenta',
    'IBAN': 'IBAN',
    'Enter IBAN': 'Introduzca el IBAN',
    'IBAN must be at least 15 characters': 'El IBAN debe tener al menos 15 caracteres',
    'Search projects': 'Buscar proyectos',
    'Request To Become Owner': 'Solicitar ser propetario',
    'No finished projects': 'Numero de proyectos terminados',
    'Filter list': 'Filtrar la lista',
    'Delete': 'Borrar',
    'Copy to Clipboard': 'Copiar al portapapeles',
    'Copied': 'Copiado',
    'Enter revenue (€)': 'Introducir ingresos (€)',
    'Loading': 'Cargando',
    'Required': 'Requerido',
    'Enter quota': 'Introducir cuota',
    'Search users': 'Buscar usuarios',
    'Enter User Address': 'Introducir dirección de usuario',
    'Basis': 'Base',
    'Funding Commision/Invest': 'Comisión finaciación',
    'Amount': 'Importe',
    'Add members': 'Añadir miembros',
    'CSF name': 'CSF Nombre',
    'CSF %': 'CSF %',
    'Project Name required': 'Nombre del proyecto requerido',
    'Project Name': 'Nombre del proyecto',
    'Enter Project name': 'Introduzca el nombre del proyecto',
    'Add': 'Añadir',
    'File size should not be larger than 3MB': 'El tamaño del archivo no debe superar los 3 MB',
    'Allowed file types are: .jpg, .jpeg, .png and .tiff': 'Los tipos de archivo permitidos son: .jpg, .jpeg, .png and .tiff',
    'Upload Poster': 'Cargar cartel',
    'Your info': 'Tus datos',
    'Save': 'Guardar',
    'Login': 'Iniciar sesión',
    'Please provide valid Email': 'Por favor, introduzca um email válido',
    'Email': 'Email – Correo electrónico',
    'Enter Email': 'Introducir Email',
    'Password': 'Contraseña',
    'Enter Password': 'Introducir contraseña',
    'Forgot password?': 'Ha olvidado su contraseña?',
    'Sign In': 'Iniciar sesión',
    'Do you want to register? Sign Up': 'Desea registrarse? Registrarse',
    'New password': 'Nueva contraseña',
    'Passwords do not match': 'Las contraseñas no coinciden',
    'Confirm Password': 'Confirmar contraseña',
    'Already have an account? Sign in': 'Ya tiene una cuenta? Iniciar sesión',
    'Reset password': 'restablecer contraseña',
    'Password not strong enough': 'Contraseña no es lo suficientemente segura',
    'Sign Up': 'Regístrate',
    'The verification link has been sent to your email.': 'El enlace de verificación ha sido enviado a su correo electrónico',
    "Your account has been created. If you don't see the email please check your spam folder or click the button bellow to resend it.": "Su cuenta ha sido creada. Si no ves el correo electrónico, comprueba tu carpeta de correo no deseado o haz clic en el botón de abajo para reeviarlo",
    'Resend email': 'Reenviar correo electrónico',
    'Sign in with a different account': 'Iniciar sesión con otra cuenta',
    'The confirmation link has been expired. Use resend Confirmation to get new verification link': 'El enlace de confirmación ha caducado. Utilice el reevío de confirmación para obtener un nuevo enlace de verificación.',
    'Resend Confirmation': 'Resend Confirmation',
    'Account verification is in process': 'Verificación de cuenta en curso',
    'Forgot password': 'Contraseña olvidada',
    'Confirmation Link has Expired, Use resend Confirmation to get new link': 'El enlace de confirmación ha caducado.Reenvíe la confirmación para obtener un nuevo enlace.',
    'Your account has been verified': 'Su cuenta ha sido verificada.',
    'Data saved.': 'Datos guardados',
    'Request has been sent to the admin.': 'La solicitud ha sido enviada al administrador',
    'Project created.': 'Proyecto creado.',
    'Revenue added.': 'Ingresos añadidos',
    'Picture Lock set.': 'Bloqueo de imagen establecido',
    'Success': 'Èxito',
    'Quota updated.': 'Cuota actualizada',
    'Confirmation Link has been sent. Dont forget to check spam folder': 'Enlace de confirmación ha sido enviado. No se olvide de comprobar la carpeta de spam.',
    'Reset Link has been sent. Dont forget to check spam folder': 'Se ha enviado el enlace de restablecimiento. No se olvide revisar la carpeta de spam.',
    'Password has been reset.': 'Se ha restablecido la contraseña.',
    'Add Revenue': 'Añadir ingresos',
    'Portuguese': 'Portugués',
    'French': 'Francés',
    'Spanish': 'Español',
    'German': 'Alemán',
    'English': 'Inglés',
    'Rows per page:': 'Filas por página:',
    'Please use a valid e-mail address.': 'Please use a valid e-mail address.',
    'Play sound on Sign In': 'Play sound on Sign In',
    'No projects': 'No projects',
    'For a better experience use our mobile app.': 'For a better experience use our mobile app.',
}
