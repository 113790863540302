import { useAppDeps } from "../../App";
import { setProjectDataAction } from "../../context/App/App.actions";
import { useAppDispatch } from "../../context/App/App.context";
import { translate } from "../localization/translate";
import useToast from "./useToast";

export default function useAuth() {
    const { authService } = useAppDeps();
    const dispatch = useAppDispatch();
    const toast = useToast();
    const { t } = translate();

    const login = async (email, password) => {
        try {
            await authService.login(email, password);
        } catch (e) {
            toast.show({
                message: e.response.data.message,
                severity: 'error',
                wait: 3000,
            });

            throw e;
        }
    }

    const signup = async (firstName, lastName, email, password, street, additionalAddress, city, postalCode, phoneNo, accountName, iban, hasConsentToToS, hasConsentToPrivacyPolicy) => {
        try {
            await authService.signUp(firstName, lastName, email, password, street, additionalAddress, city, postalCode, phoneNo, accountName, iban, hasConsentToToS, hasConsentToPrivacyPolicy);
        } catch (e) {
            toast.show({
                message: e.response.data.message,
                severity: 'error',
                wait: 3000,
            });

            throw e;
        }
    }

    const resendConfirmation = async (jsonBody) => {
        try {
            const response = await authService.resendConfirmation(jsonBody);
            if (response === "confirmaton link has been sent") {
                toast.show({
                    message: t('Confirmation Link has been sent. Dont forget to check spam folder'),
                    severity: 'success',
                    wait: 3000,
                });
                return false;
            }

        } catch (e) {
            toast.show({
                message: e.response.data.message,
                severity: 'error',
                wait: 3000,
            });

            throw e;
        }
    }

    const sendResetPasswordLink = async (jsonBody) => {
        try {
            const response = await authService.sendResetPasswordLink(jsonBody);

            toast.show({
                message: t('Reset Link has been sent. Dont forget to check spam folder'),
                severity: 'success',
                wait: 3000,
            });

            return response.data;

        } catch (e) {
            toast.show({
                message: e.response.data.message,
                severity: 'error',
                wait: 3000,
            });

            throw e;
        }
    }

    const resetPassword = async (jsonBody) => {
        try {
            const response = await authService.resetPassword(jsonBody);

            toast.show({
                message: t('Password has been reset.'),
                severity: 'success',
                wait: 3000,
            });

            return response.data;

        } catch (e) {
            toast.show({
                message: e.response.data.message,
                severity: 'error',
                wait: 3000,
            });

            throw e;
        }
    }

    const getLoggedInUser = async () => {
        try {
            const response = await authService.getUser();

            return response;
        } catch (e) {
            toast.show({
                message: e.response.data.message,
                severity: 'error',
                wait: 3000,
            });

            throw e;
        }
    }

    const sendConfirmation = async (jsonBody) => {
        try {
            const response = await authService.sendConfirmation(jsonBody);

            toast.show({
                message: t('Confirmation Link has been sent. Dont forget to check spam folder'),
                severity: 'success',
                wait: 3000,
            });

            return response;

        } catch (e) {
            toast.show({
                message: e.response.data.message,
                severity: 'error',
                wait: 3000,
            });

            throw e;
        }
    }

    const logout = async() => {
        await authService.logout();
        dispatch(setProjectDataAction(null));
        window.location.replace('/');
    }

    return {
        login,
        signup,
        logout,
        resendConfirmation,
        sendResetPasswordLink,
        resetPassword,
        getLoggedInUser,
        sendConfirmation,
    };
}