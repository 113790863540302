import { Grid } from "@mui/material";
import PieChartComponent from "../../../components/PieChart/PieChartComponent";
import { translate } from "../../../utils/localization/translate";
import styles from "./ProjectListComponent.module.scss";

export const ProjectRevenueComponent = ({ userProjects }) => {
  const { t } = translate();

  const getChartData = () => {
    const data = [];

    userProjects.forEach((item) => {
      if (item.projectDetails.userTO) {
        data.push({ label: item.projectName, value: item.projectDetails.userTO.paid });
      }
    });

    if (data.length < 1) {
      return [{ label: t('No finished projects'), value: 0 }];
    }

    return data;
  }

  return (
    <Grid item className={styles.pieGrid} boxShadow='0px 0px 1px #0c1a03, 0px 3px 8px -1px #32320e' borderRadius='16px'>
      <PieChartComponent
        title={t('Projects Revenue')}
        chartData={getChartData()}
        chartColors={[
          '#CB1517',
          '#D02C2E',
          '#D54345',
          '#DA5B5C',
          '#DF7273',
          '#EAA1A2',
        ]}
      />
    </Grid>
  );
}