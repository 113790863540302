import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Drawer } from '@mui/material';

// hooks
import useResponsive from '../../utils/hooks/useResponsive';
// components
import Logo from '../Logo/Logo';
import Scrollbar from '../Scrollbar/Scrollbar';
import NavSection from '../NavSection/NavSection';
import { HOME_PAGE, PROJECTS_PAGE } from '../../router/constants/RouteNames';
import Iconify from '../Iconify/Iconify';
import { translate } from '../../utils/localization/translate';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

// ----------------------------------------------------------------------

Sidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function Sidebar({ isOpenSidebar, onCloseSidebar }) {
  const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
  const { t } = translate();

  const navConfig = [
    {
      title: t('Home'),
      path: HOME_PAGE,
      icon: getIcon('eva:pie-chart-2-fill'),
    },
    {
      title: t('Projects'),
      path: PROJECTS_PAGE,
      icon: getIcon('eva:bulb-fill'),
    },
  ];

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 0, display: 'inline-flex' }}>
        <Logo />
      </Box>
      <NavSection navConfig={navConfig} />
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH, color: 'white' },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: '#F9FAFB',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
