import { Formik, Form, Field, ErrorMessage } from 'formik';
import React, { useState } from 'react';
import Decimal from 'decimal.js-light';
import { Grid, Typography, FormControlLabel, Button, Switch, TextField, Input, InputAdornment } from "@mui/material";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import styles from './CreateProjectForm.module.scss';
import useProjectService from '../consumers/useProjectService';
import { SectionStyle } from './CreateProjectForm.styles';
import ProjectUserTable from '../../../components/ProjectUserTable/ProjectUserTable';
import Iconify from '../../../components/Iconify/Iconify';
import { SearchResults } from '../../../components/SearchBar/SearchResults';
import useUserService from '../../User/consumers/useUserService';
import { FormProvider } from '../../../components/Hook-Form';
import { translate } from '../../../utils/localization/translate';

Decimal.set({ precision: 16 });

export const AddUsersForm = ({ projectId, closeForm, refetchData }) => {
    const { addUsers } = useProjectService();
    const { findUser } = useUserService();
    const { t } = translate();

    const [searchQuery, setSearchQuery] = useState('');
    const [foundUsers, setFoundUsers] = useState(null);
    const [isSearching, setIsSearching] = useState(false);

    const handleOnInputChange = async (e) => {
        const searchValue = e.target.value;

        setSearchQuery(searchValue);
    }

    const onSearch = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        setIsSearching(true);
        const users = await findUser(searchQuery);

        setFoundUsers(users);
        setIsSearching(false);
    }

    const initialValues = {
        address: "",
        basis: 0,
        fundingCommision: false,
        amount: 0
    }

    const [projectUser, setProjectUser] = useState({
        address: "",
        basis: 0,
        fundingCommision: false,
        amount: 0
    })

    const [projectUserArray, setProjectUserArray] = useState([]);

    const updateProjectUser = (value, attribute) => {
        if (attribute === "address") {
            setProjectUser(preVal => ({ ...preVal, address: value }))
        }
        if (attribute === "basis") {
            setProjectUser(preVal => ({ ...preVal, basis: value }))
        }
        if (attribute === "fundingCommision") {
            if (!value) {
                setProjectUser(preVal => ({ ...preVal, amount: 0, fundingCommision: value }))
            } else {
                setProjectUser(preVal => ({ ...preVal, fundingCommision: value }))
            }
        }
        if (attribute === "amount") {
            setProjectUser(preVal => ({ ...preVal, amount: value }))
        }
        if (attribute === "reset") {
            setProjectUser(() => ({
                address: "",
                basis: 0,
                fundingCommision: false,
                amount: 0
            }))
        }
    }

    const removeProjectUser = (index) => {
        setProjectUserArray(preVal => preVal.filter((val, i) => i !== index))
    }


    const addProjectUser = () => {
        if (projectUser.address.trim() === "" || projectUser.basis === 0 || (projectUser.fundingCommision && projectUser.amount === 0)) {
            return null
        }

        const user = JSON.parse(JSON.stringify(projectUser))

        if (user.fundingCommision === true) {
            user.fundingCommision = 1
        }
        else if (user.fundingCommision === false) {
            user.fundingCommision = 0
        }

        // user.basis = new Decimal(user.basis).div(100).times(10000).toDecimalPlaces(0).toNumber();
        // user.amount = parseInt(user.amount, 10);
        user.basis = parseInt(new Decimal(user.basis).toDecimalPlaces(0), 10);
        user.amount = parseInt(new Decimal(user.amount).times(100), 10);

        setProjectUserArray(preVal => [...preVal, user])
        updateProjectUser(null, "reset")
    }

    /**
    * Gets called on Sign in
    */
    const handleOnSubmit = async (values, props) => {
        const jsonBody = {
            "projectId": projectId,
            "users": projectUserArray.map(Object.values)
        }

        await addUsers(jsonBody);

        setProjectUserArray([]);
        closeForm();
        refetchData();

        props.resetForm();
        props.setSubmitting(false);
    };

    const isSubmitDisabeled = () => {
        return projectUserArray.length < 1;
    }

    return (
        <div className={styles.root}>
            <FormProvider autoComplete="on" onSubmit={(e) => onSearch(e)} methods={{ onSearch }}>
                <div className={styles.searchBar}>
                    <Input
                        autoFocus
                        fullWidth
                        disableUnderline
                        placeholder={`${t('Search users')}…`}
                        startAdornment={
                            <InputAdornment position="start">
                                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                            </InputAdornment>
                        }
                        sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
                        onChange={(e) => handleOnInputChange(e)}
                    />
                    <Button variant="contained" type="submit">
                        {t('Search')}
                    </Button>
                </div>

                {!isSearching && foundUsers &&
                    <SearchResults results={foundUsers} />
                }
            </FormProvider>
            <Formik onSubmit={handleOnSubmit} initialValues={initialValues}>
                {(props) => (
                    <Form>
                        <Grid className={`${styles.formContainer} ${styles.formContainerModal}`} container spacing="1" alignItems="center">
                            <Typography variant="contained" align="left" sx={{ color: 'text.primary', mt: 2, mb: -2, fontWeight: 'bold' }}>
                                {t('Add members')}
                            </Typography>
                            <SectionStyle className={styles.userSectionModal}>
                                <div className={styles.inputRow}>
                                    <Field as={TextField}
                                        style={{ minWidth: '70%', marginTop: 10, marginBottom: 10, padding: 2 }}
                                        className={styles.textField}
                                        name="address"
                                        label={t('Address')}
                                        placeholder={t('Enter User Address')}
                                        value={projectUser.address}
                                        onChange={(e) => updateProjectUser(e.target.value, "address")}
                                        fullWidth
                                        helperText={<b className={styles.errorText}> <ErrorMessage name="quota" /> </b>}
                                    />
                                    <Field as={TextField}
                                        style={{ marginTop: 10, marginBottom: 10, padding: 2 }}
                                        className={styles.textField}
                                        name="basis"
                                        label="bps"
                                        placeholder={t('Basis')}
                                        value={projectUser.basis === 0 ? "" : projectUser.basis}
                                        onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                                        onChange={(e) => updateProjectUser(e.target.value, "basis")}
                                        inputProps={{ required: false }}
                                    />
                                </div>
                                <div className={styles.inputRow} style={{ justifyContent: 'space-between' }}>
                                    <FormControlLabel style={{ marginTop: 10, marginBottom: 10, padding: 2, whiteSpace: 'nowrap' }}
                                        control={<Switch checked={projectUser.fundingCommision} onChange={(e) => {
                                            updateProjectUser(e.target.checked, "fundingCommision")
                                        }}
                                            inputProps={{ 'aria-label': 'controlled' }} />}
                                        label={t('Funding Commision/Invest')}
                                        labelPlacement="end"
                                    />
                                    <Field as={TextField}
                                        className={styles.inputFieldSm}
                                        style={{ marginTop: 10, marginBottom: 10, marginLeft: 10, padding: 2 }}
                                        value={projectUser.amount === 0 ? "" : projectUser.amount}
                                        onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                                        onChange={(e) => updateProjectUser(e.target.value, "amount")}
                                        disabled={!projectUser.fundingCommision}
                                        placeholder="€"
                                        type="number"
                                        label={t('Amount')}
                                    />
                                </div>
                                <Button
                                    className={styles.addUserButton}
                                    onClick={addProjectUser}
                                >
                                    <AddRoundedIcon /> {t('Add')}
                                </Button>
                            </SectionStyle>

                            <ProjectUserTable projectUser={projectUserArray} removeProjectUser={removeProjectUser} />
                        </Grid>

                        <div className={styles.buttonGroup}>
                            <Button variant="contained" className={styles.closeModalButton} fullWidth onClick={() => closeForm()}>
                                {t('Cancel')}
                            </Button>

                            <Button className={styles.buttonPrimary} type="submit" variant="contained" fullWidth disabled={isSubmitDisabeled() || props.isSubmitting}>
                                {props.isSubmitting ? t('Loading') : t('Add members')}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}