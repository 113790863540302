import React from 'react';
import PropTypes from 'prop-types';
import { matchPath, useLocation } from 'react-router-dom';
import { Box, List } from '@mui/material';
import { NavItem } from '../NavItem/NavItem';
import { useAppDeps } from '../../App';
import { USER_KEY } from '../../services/StorageService';
import { PROJECTS_PAGE } from '../../router/constants/RouteNames';

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();
  const { storageService } = useAppDeps();
  const jsonUser = storageService.getItem(USER_KEY);
  const user = JSON.parse(jsonUser);

  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {navConfig.map((item) => {
          if (item.path === PROJECTS_PAGE) {
            if (user.role !== 'notary') {
              return <NavItem key={item.title} item={item} active={match} />;
            }
          } else {
            return <NavItem key={item.title} item={item} active={match} />;
          }
        })}
      </List>
    </Box>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
};
