import { AppActionType } from './App.types';

export const setProjectDataAction = (payload) => ({
  type: AppActionType.SET_PROJECT_DATA,
  payload,
});

export const setLanguageAction = (payload) => ({
  type: AppActionType.SET_LANGUAGE,
  payload,
});

export const setBreakdownChartDataAction = (payload) => ({
  type: AppActionType.SET_BREAKDOWN_CHART,
  payload,
});